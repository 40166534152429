import React               from 'react';
import PropTypes           from 'prop-types';
import classNames          from 'classnames';
import bugsnag             from '@bugsnag/js';
import { animateScroll }   from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BulkActions    from '~/actions/bulk_actions';
import APIRequest     from '~/lib/api_request';
import EmailForm      from '~/components/forms/email_form';
import RecipientItem  from '~/components/modals/recipients/recipient_item';
import AppModal       from './app_modal';
import ScheduleButton from '~/components/drawers/lead_drawer/forms/ScheduleForm/ScheduleButton';

import Helpers              from '~/helpers/helpers';
import { DOMAIN_MESSAGES }  from '~/helpers/constants';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';

const { currentTeam } = Rails.helpers;

class SendEmailModal extends React.Component {
  constructor(props) {
    super(props);

    const { currentUser } = Rails.helpers;

    this.state = {
      loadingBulkLimits: false,
      bulkLimitsReached: false,
      submitting:        false,
      loadingRecipients: false,
      isConfirming:      false,
      renderContent:     false,
      email:             {
        from:           '',
        subject:        '',
        body:           '',
        cc_emails:      '',
        attachment_ids: '',
      },
      recipients:        [],
      recipientIDs:      [],
      errors:            {},
      responseError:     null,
      visibleRecipients: 10,
      message:           {
        type: null,
        text: '',
      },
      loadingValidation:     false,
      disableSendButton:     false,
      schedule:              { customOptions: { timezone: currentUser.timezone } },
      scheduleEmailsAllowed: checkFeatureFlag(process.env.ALLOW_SCHEDULE_EMAILS_FLAG),
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.loadRecipients();
  }

  assignEmailState = (email) => {
    this.setState({ email });
  };

  setSchedule = (newSchedule) => {
    this.setState((prevState) => ({
      schedule: { ...prevState.schedule, ...newSchedule },
    }));
  };

  loadRecipients = () => {
    this.setState({ loadingRecipients: true }, () => {
      const {
        table,
        searchData,
        bulkSelecting,
        selectedItems,
        unSelectedItems,
        blastTeam,
      } = this.props;

      let selectedLeads = Object.values(selectedItems);
      const unselectedLeads = Object.values(unSelectedItems);

      let emailData;

      if (bulkSelecting) {
        selectedLeads = 'all';
        emailData = {
          ...searchData,
          ids:            'all',
          unselected_ids: _lodash.map(unselectedLeads, 'id'),
        };
      } else if (blastTeam) {
        selectedLeads = 'blast';
        emailData = { ...searchData, ids: 'blast' };
      } else {
        emailData = {
          ...searchData,
          ids: _lodash.map(selectedLeads, 'id'),
        };
      }

      APIRequest.post({
        resource: '/v1/lead_bulk_emails/recipients',
        data:     emailData,
      }).end((error, response) => {
        this.setState({ loadingRecipients: false }, () => {
          if (!error) {
            const recipients = response.body;

            this.setState(
              {
                recipients,
                recipientIDs: _lodash.map(recipients, 'id'),
              },
              () => {
                this.validateBulkLimits();
              },
            );
          }
        });
      });
    });
  };

  validateBulkLimits = () => {
    const { recipients } = this.state;

    this.setState({ loadingBulkLimits: true }, () => {
      APIRequest.get({
        resource: '/v1/bulk_actions/validate_limits',
        data:     {
          count_of_contacts: recipients.length,
        },
      }).end((error, response) => {
        this.setState({ loadingBulkLimits: false }, () => {
          if (!error) {
            const {
              bulk_message_frequency_limit,
              bulk_message_size_limit,
              reached_limits,
            } = response.body;

            this.setState({
              bulkLimitsReached:         reached_limits,
              bulkMessageFrequencyLimit:
                bulk_message_frequency_limit || 'unlimited',
              bulkMessageSizeLimit:
                bulk_message_size_limit || 'unlimited',
              renderContent: true,
            });
          }
        });
      });
    });
  };

  validateFromDomainAndRecipients = async (address) => {
    const { recipients } = this.state;
    const recipientsCount = recipients.length;
    const domain = Helpers.extractDomainFromEmail(address);
    const maxRecipients = parseInt(
      process.env.UNCONFIG_DOMAIN_RECIPIENTS_LIMIT,
      10,
    );

    if (!domain) {
      this.setState({
        message: {
          type: null,
          text: '',
        },
        disableSendButton: false,
      });

      return;
    }

    this.setState({ loadingValidation: true });

    try {
      const isDomainConfigured = await this.fetchDomainConfigurationStatus(domain);

      if (!isDomainConfigured) {
        if (recipientsCount <= maxRecipients) {
          this.setState({
            message:           DOMAIN_MESSAGES.UNCONFIGURED_DOMAIN_WARNING,
            disableSendButton: false,
          });
        } else {
          this.setState({
            message: DOMAIN_MESSAGES.UNCONFIGURED_DOMAIN_ERROR(
              recipientsCount,
              maxRecipients,
            ),
            disableSendButton: true,
          });
        }
      } else {
        this.setState({
          message: {
            type: null,
            text: '',
          },
          disableSendButton: false,
        });
      }
    } catch (error) {
      this.setState({
        message:           DOMAIN_MESSAGES.VALIDATION_ERROR,
        disableSendButton: true,
      });
    }

    this.setState({ loadingValidation: false });
  };

  fetchDomainConfigurationStatus = (domain) => new Promise((resolve, reject) => {
    APIRequest.get({
      resource: '/v1/email_domains/validate_domain',
      data:     { domain },
    }).end((error, response) => {
      if (!error) {
        resolve(response.body.is_configured);
      } else {
        reject(error);
      }
    });
  });

  handleNext = (e) => {
    e.preventDefault();

    const errors = this.emailForm.validate();
    if (!_lodash.size(errors)) {
      this.setState({ isConfirming: true, errors: null });
    } else {
      this.setState({ errors });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const $modal = $(this.appModal.modal);

    this.setState({ submitting: true }, () => {
      const { searchData, blastTeam, reloadLeads } = this.props;
      const { recipientIDs, email, schedule } = this.state;

      const emailData = { ids: recipientIDs, ...searchData };

      const defaults = {
        attachment_ids: _lodash.map(email.attachments, 'id'),
      };

      APIRequest.post({
        resource: '/v1/lead_bulk_emails',
        data:     {
          ...defaults, ...emailData, ...email, schedule,
        },
      }).end((error, response) => {
        this.setState({ submitting: false }, () => {
          if (!error) {
            $modal.modal('hide');

            if (!blastTeam) {
              reloadLeads();
            }

            BulkActions.createAction('email');

            const notifyMessage = schedule?.selectedSchedule ? 'Your email is scheduled to be sent' : 'Your email is in the send queue and will go out shortly';

            GlobalContainer.notify(
              notifyMessage,
            );
          } else {
            if (response.status === 500) { // server error
              const bugsnagClient = bugsnag(
                process.env.BUGSNAG_API_KEY,
              );

              this.setState({ responseError: true });

              if (bugsnagClient) {
                bugsnagClient.notify(error, { context: response.text });
              }
            }

            GlobalContainer.notify(response?.body?.error || response.text, 'error');
          }
        });
      });
    });
  };

  handleRecipientSelect = (leadId, selected) => {
    const { recipientIDs } = this.state;

    const leadIdx = recipientIDs.indexOf(leadId);
    const leadSelected = leadIdx > -1;

    if (leadSelected) {
      if (!selected) {
        this.setState((prevState) => {
          const nextIds = [...prevState.recipientIDs];
          nextIds.splice(leadIdx, 1);

          return { recipientIDs: nextIds };
        });
      }
    } else if (selected) {
      this.setState((prevState) => ({
        recipientIDs: [...prevState.recipientIDs, leadId],
      }));
    }
  };

  loadMore = () => {
    this.setState(
      (prev) => ({ visibleRecipients: prev.visibleRecipients + 10 }),
      () => {
        this.scrollToBottom();
      },
    );
  };

  scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: 'recipients-table',
    });
  };

  renderRecipients = () => {
    const { recipients, loadingRecipients, visibleRecipients } = this.state;

    const leadRows = recipients.slice(0, visibleRecipients).map((lead, idx) => (
      <RecipientItem
        lead={lead}
        key={`lead-item-${lead.id}`}
        handleRecipientSelect={this.handleRecipientSelect}
      />
    ));

    const containerStyle = {
      maxHeight: '400px',
      overflowY: 'scroll',
    };

    const containerClass = classNames(GlobalContainer.product());

    return (
      <div
        className={containerClass}
        style={containerStyle}
        id="recipients-table"
      >
        <table className="table table-hover main leads-table mb0 no-border">
          <tbody>
            {leadRows.length === 0 && loadingRecipients ? (
              <tr>
                <td className="text-center">Loading recipients...</td>
              </tr>
            ) : (
              leadRows
            )}
          </tbody>
        </table>
      </div>
    );
  };

  renderConfirming = () => {
    const { blastTeam } = this.props;
    const {
      submitting,
      email,
      recipientIDs,
      loadingRecipients,
      responseError,
      visibleRecipients,
      schedule,
      scheduleEmailsAllowed,
    } = this.state;
    const selectedItemsCount = recipientIDs.length;

    if (loadingRecipients) {
      return (
        <>
          <div className="modal-body" style={{ minHeight: '50vh' }}>
            <h4 className="text-center">
              Loading Recipients
              {' '}
              <FontAwesomeIcon
                icon="far fa-spinner"
                pulse
                size="lg"
              />
            </h4>
          </div>
        </>
      );
    }

    if (responseError) {
      return (
        <>
          <div className="modal-body">
            {this.renderResponseError()}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                this.setState({
                  isConfirming:  false,
                  responseError: false,
                  message:       {
                    type: null,
                    text: '',
                  },
                });
              }}
            >
              Back
            </button>
          </div>
        </>
      );
    }

    let takeOffHeader = `${selectedItemsCount} people`;

    // TODO: count correct retention agents for blasting
    if (blastTeam) {
      takeOffHeader = 'All team agents';
    }

    const sendButtonClass = classNames('btn btn-primary mr-0', {
      disabled: submitting,
    });

    return (
      <>
        <div className="modal-body">
          <h4 className="text-center">Prepare for takeoff ...</h4>
          <div className="mt30 mb30 text-center">
            <h2>{takeOffHeader}</h2>
            <p className="lead text-grey">will receive</p>
            <p className="lead mt30 mb30">{email.subject}</p>
            {this.renderRecipients()}

            {visibleRecipients < selectedItemsCount && (
              <button
                onClick={this.loadMore}
                type="button"
                className="btn btn-primary mt-3"
              >
                Load more ...
              </button>
            )}
          </div>
        </div>

        <div className="modal-footer" ref={this.containerRef}>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              this.setState({
                isConfirming:  false,
                responseError: false,
                message:       {
                  type: null,
                  text: '',
                },
              });
            }}
          >
            Back
          </button>
          <div className="btn-group" role="group" aria-label="email-group">
            <button
              type="submit"
              className={sendButtonClass}
              disabled={submitting}
            >
              Send
            </button>

            {scheduleEmailsAllowed && (
              <ScheduleButton
                schedule={schedule}
                setSchedule={this.setSchedule}
                disabled={false}
                popoverPlacement="top"
                containerRef={this.containerRef}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  renderContent() {
    const {
      message, submitting, errors, loadingValidation, disableSendButton,
    } = this.state;

    const sendButtonClass = classNames('btn btn-primary', {
      disabled: submitting || loadingValidation || disableSendButton,
    });

    return (
      <>
        <div className="modal-body">
          {message.text && (
            <div
              className={`alert alert-${message.type} mb15`}
              role="alert"
            >
              {message.text}
              {' '}
              <a
                href="/team/edit?tab=email_settings"
                target="_blank"
                rel="noopener noreferrer"
              >
                Configure Now
              </a>
            </div>
          )}

          <EmailForm
            ref={(el) => {
              this.emailForm = el;
            }}
            onChange={this.assignEmailState}
            errors={errors}
            enableFromField
            validateFromDomainAndRecipients={
              this.validateFromDomainAndRecipients
            }
          />
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            className={sendButtonClass}
            onClick={this.handleNext}
            disabled={
              submitting || loadingValidation || disableSendButton
            }
          >
            Next
          </button>
        </div>
      </>
    );
  }

  renderResponseError = () => {
    const { responseError } = this.state;

    if (responseError) {
      return (
        <div className="p20 text-center">
          <div className="mb30">
            <h2>Oh no, looks like we had an issue.</h2>
          </div>
          <p className="lead">
            Please try again, or refresh the page. Our site admins
            have been notified and we&apos;ll have this fixed shortly.
          </p>
        </div>
      );
    }

    return null;
  };

  renderBulkVallidationError = () => {
    const { bulkMessageFrequencyLimit, bulkMessageSizeLimit } = this.state;
    const bulkMessageLookbackPeriod = process.env.BULK_MESSAGE_LOOKBACK_PERIOD / 24;

    const trialText = (
      <>
        while on a trial. Your limits will be increased at the end of
        the trial to those of your selected pricing plan seen
        {' '}
        <a href="/billing" target="_blank" rel="noopener noreferrer" className="alert-link">
          here.
        </a>
      </>
    );

    const mainText = (
      <>
        on your pricing plan. Please upgrade your pricing plan
        {' '}
        <a href="/billing" target="_blank" rel="noopener noreferrer" className="alert-link">
          here
        </a>
        {' '}
        to increase your limits.
      </>
    );

    return (
      <div className="p20 text-center">
        <div className="alert alert-danger" role="alert">
          Bulk messaging actions can only be triggered on up to
          {' '}
          {bulkMessageSizeLimit}
          {' '}
          contacts up to
          {' '}
          {bulkMessageFrequencyLimit}
          {' '}
          times within
          {' '}
          {bulkMessageLookbackPeriod}
          {' '}
          days
          {' '}
          {currentTeam.trial ? trialText : mainText}
        </div>
      </div>
    );
  };

  renderBody = () => {
    const {
      isConfirming,
      bulkLimitsReached,
      loadingBulkLimits,
      renderContent,
    } = this.state;

    if (loadingBulkLimits) {
      return (
        <div className="text-center p40">
          <FontAwesomeIcon
            icon="far fa-spinner"
            pulse
            className="fa-lg"
          />
        </div>
      );
    }

    if (bulkLimitsReached) {
      return this.renderBulkVallidationError();
    }

    if (renderContent && isConfirming) {
      return this.renderConfirming();
    }

    if (renderContent && !isConfirming) {
      return this.renderContent();
    }

    return null;
  };

  render() {
    const { blastTeam } = this.props;
    const { submitting, isConfirming } = this.state;

    let modalHeader = (
      <>
        {isConfirming ? 'Confirming ' : ''}
        Bulk Email
      </>
    );

    if (blastTeam) {
      modalHeader = (
        <>
          {isConfirming ? 'Confirming ' : ''}
          Mass Email
          <small> to All team agents</small>
        </>
      );
    }

    const spinnerImg = (
      <FontAwesomeIcon icon="far fa-spinner" pulse />
    );

    return (
      <AppModal
        ref={(appModal) => (this.appModal = appModal)}
        dialogClass="modal-dialog modal-lg"
      >
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              {modalHeader}
              {submitting && spinnerImg}
            </h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          {this.renderBody()}
        </form>
      </AppModal>
    );
  }
}

SendEmailModal.defaultProps = {
  blastTeam:          false,
  bulkSelecting:      false,
  records:            {},
  searchData:         {},
  selectedItems:      {},
  table:              {},
  unSelectedItems:    {},
};

SendEmailModal.propTypes = {
  blastTeam:          PropTypes.bool,
  bulkSelecting:      PropTypes.bool,
  records:            PropTypes.shape({}),
  searchData:         PropTypes.shape({}),
  selectedItems:      PropTypes.shape({}),
  table:              PropTypes.shape({}),
  unSelectedItems:    PropTypes.shape({}),
};

export default SendEmailModal;

import autoBind            from 'react-autobind';
import qs                  from 'qs';
import { v4 as uuidv4 }    from 'uuid';
import classNames          from 'classnames';
import React               from 'react';
import ReactDOM            from 'react-dom';
import PropTypes           from 'prop-types';
import { Link }            from 'react-router-dom';
import ReactPaginate       from 'react-paginate';
import pluralize           from 'pluralize';
import { Nav, NavItem }    from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit                from '~/lib/brokerbit';
import LeadHelpers              from '~/helpers/lead_helpers';
import { TeammatePicker }       from '~/components/forms/team_member_picker';
import { BucketSelect }         from '~/components/forms/lead_fields';
import AgentActions             from '~/actions/agent_actions';
import AgentStore               from '~/stores/agent_store';
import BulkSelectActions        from '~/actions/bulk_select_actions';
import BulkSelectStore          from '~/stores/bulk_select_store';
import AddTaskModal             from '~/components/modals/add_task_modal';
import AddNoteModal             from '~/components/modals/add_note_modal';
import SendEmailModal           from '~/components/modals/send_email_modal';
import SendSMSModal             from '~/components/modals/send_sms_modal';
import AddCampaignModal         from '~/components/modals/add_campaign_modal';
import RemoveCampaignModal      from '~/components/modals/remove_campaign_modal';
import AddLabelModal            from '~/components/modals/add_label_modal';
import RemoveLabelModal         from '~/components/modals/remove_label_modal';
import EditTaskDescriptionModal from '~/components/modals/edit_task_description_modal';
import TaskStatusSelect         from '~/components/forms/task_status_select';
import TaskCategorySelect       from '~/components/forms/task_category_select';
import TaskPrioritySelect       from '~/components/forms/task_priority_select';
import DateTimePicker                from '~/components/forms/DateTimePicker';
import StatusSelect             from '~/components/forms/dropdowns/StatusSelect';

import LeadsSelect               from '~/components/forms/leads_select';
import DataTableProductionFilter from './data_table_production_filter';
import DataTableSortLink         from './data_table_sort_link';
import DataTableItem             from './data_table_item';
import DocumentActions           from '~/actions/document_actions';
import DocumentStore             from '~/stores/document_store';
import DocumentLimitAlertChecker from '~/components/shared/document_limit_alert_checker';
import {
  checkIfLimitExceeded,
}                                from '~/helpers/document_helpers';

const scopeToEntity = {
  today_or_past_tasks: 'tasks',
  tasks_due_later:     'tasks',
  tasks_overdue:       'tasks',
  all_tasks:           'tasks',
  documents:           'documents',
  trash_documents:     'documents',
};

class DataTableContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching:           false,
      tableFilters:       {},
      tableSortField:     props.tableSortField,
      tableSortDirection: props.tableSortDirection,
      unSelectedItems:    {},
      selectedItems:      {},
      selectAllItems:     false,
      categories:         [],
      statuses:           [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this.listener = AgentStore.addListener(this.onAgentStoreChange);
    this.bulkSelectlistener = BulkSelectStore.addListener(this.onBulkSelectStoreChange);
    DocumentActions.loadCategories();
    DocumentActions.loadStatuses();
    this.documentStoreListener = DocumentStore.addListener(() => {
      const { categories, statuses, lastDocumentStoreAction } = DocumentStore.getState();

      if (lastDocumentStoreAction === 'loadCategoriesDone') {
        this.setCategories(categories);
      }

      if (lastDocumentStoreAction === 'loadStatusesDone') {
        this.setStatuses(statuses);
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { table, records } = nextProps;
    const { selectAllItems } = this.state;

    if (selectAllItems) {
      setTimeout(() => {
        if (table.scope === 'documents') {
          BulkSelectActions.selectDocumentItems(table, records.documents);
        } else if (table.scope === 'trash_documents') {
          BulkSelectActions.selectTrashDocumentItems(table, records.documents);
        } else {
          BulkSelectActions.selectLeadItems(table, records.data);
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
    if (this.bulkSelectlistener) this.bulkSelectlistener.remove();
    if (this.documentStoreListener) this.documentStoreListener.remove();
  }

  onAgentStoreChange() {
    const { fetching } = this.state;

    const agentStoreState = AgentStore.getState();
    const { AgentStoreAction } = agentStoreState;

    if (fetching) {
      if (AgentStoreAction === 'loadLeadsDone') {
        this.setState({ fetching: false });
      }
    }
  }

  onBulkSelectStoreChange() {
    let newState;
    const { table } = this.props;

    const bulkSelectStoreState = BulkSelectStore.getState();
    const {
      scopedSelItems,
      scopedSelAllItems,
      unselectedItems,
    } = bulkSelectStoreState;

    const selectedItems = scopedSelItems[table.scope] ? scopedSelItems[table.scope] : {};
    const selectAllItems = scopedSelAllItems[table.scope] ? scopedSelAllItems[table.scope] : false;
    const unSelectedItems = unselectedItems[table.scope] ? unselectedItems[table.scope] : {};

    newState = {
      selectedItems,
      selectAllItems,
      unSelectedItems,
    };

    this.setState(newState);
  }

  onPageChange(page) {
    const selectedPage = page.selected;

    this.reloadLeads({}, { page: selectedPage + 1 });
  }

  onBulkSelect(e) {
    const { table, records } = this.props;
    const data = records.data || records.documents;

    if (!data) return;

    if (e.target.checked) {
      if (table.scope === 'documents') {
        BulkSelectActions.selectDocumentItems(table, records.documents);
      } else if (table.scope === 'trash_documents') {
        BulkSelectActions.selectTrashDocumentItems(table, records.documents);
      } else {
        BulkSelectActions.selectLeadItems(table, records.data);
      }
    } else if (table.scope === 'documents') {
      BulkSelectActions.deselectDocumentItems(table, records.documents);
    } else if (table.scope === 'trash_documents') {
      BulkSelectActions.deselectTrashDocumentItems(table, records.documents);
    } else {
      BulkSelectActions.deselectLeadItems(table, records.data);
    }
  }

  onSelectAllRecords(e) {
    e.preventDefault();
    const { table, records } = this.props;

    if (!records.data && !records.documents) return;

    BulkSelectActions.selectAllItems(table);
    if (table.scope === 'documents') {
      BulkSelectActions.selectDocumentItems(table, records.documents);
    } else if (table.scope === 'trash_documents') {
      BulkSelectActions.selectTrashDocumentItems(table, records.documents);
    } else {
      BulkSelectActions.selectLeadItems(table, records.data);
    }
  }

  onFilteringProductions(productions) {
    const { tableFilters } = this.state;

    this.setState({
      tableFilters: { ...tableFilters, production: productions },
    });

    this.reloadLeads({ production: productions }, { page: 1 });
  }

  onSortToggle(sortField, sortDirection) {
    const { tableSortField, tableSortDirection } = this.state;

    if (tableSortDirection === 'desc' && tableSortField.column === sortField.column) {
      sortField = { column: null };
      sortDirection = null;
    }

    this.setState({
      tableSortField:     sortField,
      tableSortDirection: sortDirection,
    }, () => {
      this.reloadLeads({}, { column: sortField.column, direction: sortDirection });
    });
  }

  onAddNoteClick(e) {
    e.preventDefault();

    const { table, records } = this.props;
    const {
      selectAllItems, selectedItems, tableFilters, unSelectedItems,
    } = this.state;
    const { query } = this.props;

    const searchData = Object.assign(query);

    ReactDOM.render(
      <AddNoteModal
        reloadLeads={this.reloadLeads}
        bulkSelecting={selectAllItems}
        countSelectedItems={this.selectedItemsCount}
        records={records}
        searchData={searchData}
        selectedItems={selectedItems}
        table={table}
        unSelectedItems={unSelectedItems}
      />,
      document.getElementById('primary-modal'),
    );
  }

  onAddLabelClick(e) {
    e.preventDefault();

    const { table, records } = this.props;
    const {
      selectAllItems, selectedItems, tableFilters, unSelectedItems,
    } = this.state;
    const { query } = this.props;

    const searchData = Object.assign(query);

    ReactDOM.render(
      <AddLabelModal
        reloadLeads={this.reloadLeads}
        bulkSelecting={selectAllItems}
        countSelectedItems={this.selectedItemsCount}
        records={records}
        selectedItems={selectedItems}
        searchData={searchData}
        table={table}
        unSelectedItems={unSelectedItems}
      />,
      document.getElementById('primary-modal'),
    );
  }

  onRemoveLabelClick(e) {
    e.preventDefault();

    const { table, records } = this.props;
    const {
      selectAllItems, selectedItems, tableFilters, unSelectedItems,
    } = this.state;
    const { query } = this.props;

    const searchData = Object.assign(query);

    ReactDOM.render(
      <RemoveLabelModal
        reloadLeads={this.reloadLeads}
        bulkSelecting={selectAllItems}
        countSelectedItems={this.selectedItemsCount}
        records={records}
        selectedItems={selectedItems}
        searchData={searchData}
        table={table}
        unSelectedItems={unSelectedItems}
      />,
      document.getElementById('primary-modal'),
    );
  }

  onAddEmailClick(e) {
    e.preventDefault();

    const { table, records } = this.props;
    const {
      selectAllItems, selectedItems, tableFilters, unSelectedItems,
    } = this.state;
    const { query } = this.props;

    const searchData = Object.assign(query);

    ReactDOM.render(
      <SendEmailModal
        reloadLeads={this.reloadLeads}
        bulkSelecting={selectAllItems}
        records={records}
        searchData={searchData}
        selectedItems={selectedItems}
        table={table}
        unSelectedItems={unSelectedItems}
      />,
      document.getElementById('primary-modal'),
    );
  }

  onAddSMSClick(e) {
    e.preventDefault();

    const { table, records } = this.props;
    const {
      selectAllItems, selectedItems, tableFilters, unSelectedItems,
    } = this.state;
    const { query } = this.props;

    const searchData = Object.assign(query);

    ReactDOM.render(
      <SendSMSModal
        reloadLeads={this.reloadLeads}
        bulkSelecting={selectAllItems}
        table={table}
        records={records}
        searchData={searchData}
        selectedItems={selectedItems}
        unSelectedItems={unSelectedItems}
      />,
      document.getElementById('primary-modal'),
    );
  }

  onAddCampaignClick(e) {
    e.preventDefault();

    const { table, records } = this.props;
    const {
      selectAllItems, selectedItems, tableFilters, unSelectedItems,
    } = this.state;
    const { query } = this.props;

    const searchData = Object.assign(query);

    ReactDOM.render(
      <AddCampaignModal
        reloadLeads={this.reloadLeads}
        bulkSelecting={selectAllItems}
        countSelectedItems={this.selectedItemsCount}
        records={records}
        selectedItems={selectedItems}
        searchData={searchData}
        table={table}
        unSelectedItems={unSelectedItems}
      />,
      document.getElementById('primary-modal'),
    );
  }

  onRemoveCampaignClick(e) {
    e.preventDefault();

    const { table, records } = this.props;
    const {
      selectAllItems, selectedItems, tableFilters, unSelectedItems,
    } = this.state;
    const { query } = this.props;

    const searchData = Object.assign(query);

    ReactDOM.render(
      <RemoveCampaignModal
        reloadLeads={this.reloadLeads}
        bulkSelecting={selectAllItems}
        countSelectedItems={this.selectedItemsCount}
        records={records}
        selectedItems={selectedItems}
        searchData={searchData}
        table={table}
        unSelectedItems={unSelectedItems}
      />,
      document.getElementById('primary-modal'),
    );
  }

  onAddTaskClick(e) {
    e.preventDefault();

    const { table, records } = this.props;
    const {
      selectAllItems, selectedItems, tableFilters, unSelectedItems,
    } = this.state;
    const { query } = this.props;

    const searchData = Object.assign(query);

    ReactDOM.render(
      <AddTaskModal
        reloadLeads={this.reloadLeads}
        countSelectedItems={this.selectedItemsCount}
        bulkSelecting={selectAllItems}
        table={table}
        records={records}
        searchData={searchData}
        selectedItems={selectedItems}
        unSelectedItems={unSelectedItems}
      />,
      document.getElementById('primary-modal'),
    );
  }

  onDeleteClick(e) {
    e.preventDefault();

    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, records, query } = this.props;
    const searchData = Object.assign(query);

    let deleteData;
    let selectedLeads = Object.values(selectedItems);
    const unselectedItems = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedLeads = 'all';
      deleteData = { ...deleteData, unselected_ids: _lodash.map(unselectedItems, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();
    const selectedItemsText = pluralize('record', selectedItemsCount, true);
    const pluralPronoun = pluralize('this', selectedItemsCount);

    let title;
    let message;
    let size;

    if (GlobalContainer.product() === 'retention') {
      size = 'large';
      title = `Deleting ${pluralPronoun} ${selectedItemsText} will be permanent. Please confirm you want to proceed.`;
      message = '<div>Note: <br/> <ul><li>Deleting this user will remove their system access and delete their Retention record.</li><li>Please review all campaigns to determine if this user is set as a "Sender" and change the sender to a different user to avoid messages being sent later as this user.</li></ul></div>';
    } else {
      size = 'small';
      title = '';
      message = `Deleting ${pluralPronoun} ${selectedItemsText} will be permanent. Please confirm you want to proceed.`;
    }

    brokerbit.confirmBox({
      title,
      message,
      size,
      callback: (ok) => {
        if (ok) {
          AgentActions.bulkDeleteLeads(table, selectedLeads, searchData, deleteData);
          BulkSelectActions.deselectAllItems(table);
          this.reloadLeads();
        }
      },
    });
  }

  onBucketChange(bucket) {
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, records, query } = this.props;
    const searchData = Object.assign(query);

    let updateParams = {};
    let selectedLeads = Object.values(selectedItems);
    const unselectedItems = Object.values(unSelectedItems);

    switch (bucket.value) {
      case 'recruiting':
        updateParams = { ...updateParams, status: 'new_lead', bucket: 'backlog' };
        break;
      default:
        updateParams = { ...updateParams, bucket: bucket.value };
    }

    if (selectAllItems) {
      selectedLeads = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItems, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to move these ${selectedItemsCount} contacts to ${bucket.label}?`,
      callback: (ok) => {
        if (ok) {
          AgentActions.bulkUpdateLeads(table, selectedLeads, searchData, updateParams);
          this.reloadLeads();
        }
      },
    });
  }

  onOwnerChange(owner) {
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, records, query } = this.props;
    const searchData = Object.assign(query);

    let updateParams = { owner_id: owner.value };
    let selectedLeads = Object.values(selectedItems);
    const unselectedItems = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedLeads = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItems, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to change the owner of these ${selectedItemsCount} contacts to ${owner.label}?`,
      callback: (ok) => {
        if (ok) {
          AgentActions.bulkUpdateLeads(table, selectedLeads, searchData, updateParams);
          this.reloadLeads();
        }
      },
    });
  }

  onStatusChange(status) {
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, query } = this.props;
    const searchData = Object.assign(query);

    if (!status.value) return;

    let updateParams = { status: status.value };
    let selectedLeads = Object.values(selectedItems);
    const unselectedItems = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedLeads = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItems, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();
    brokerbit.confirmBox({
      message:  `Are you sure you want to change the status of these ${selectedItemsCount} contacts to ${status.label}?`,
      callback: (ok) => {
        if (ok) {
          AgentActions.bulkUpdateLeads(table, selectedLeads, searchData, updateParams);
          this.reloadLeads();
        }
      },
    });
  }

  onDocumentStatusChange = (status) => {
    const statusId = status.value;
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, query } = this.props;

    let updateParams = { status_id: statusId };
    let selectedDocuments = Object.values(selectedItems);
    const unselectedItemsArray = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedDocuments = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItemsArray, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to change the status of these ${selectedItemsCount} documents to ${status.label}?`,
      callback: (ok) => {
        if (ok) {
          DocumentActions.bulkUpdateDocuments(table, selectedDocuments, updateParams, query);
          BulkSelectActions.deselectAllItems(table);
        }
      },
    });
  };

  onDocumentCategoryChange = (category) => {
    const categoryId = category.value;
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, query } = this.props;

    let updateParams = { category_id: categoryId };
    let selectedDocuments = Object.values(selectedItems);
    const unselectedItemsArray = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedDocuments = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItemsArray, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to change the category of these ${selectedItemsCount} documents to ${category.label}?`,
      callback: (ok) => {
        if (ok) {
          DocumentActions.bulkUpdateDocuments(table, selectedDocuments, updateParams, query);
          BulkSelectActions.deselectAllItems(table);
        }
      },
    });
  };

  onDocumentOwnerChange = (owner) => {
    const ownerId = owner.value;
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, query } = this.props;

    let updateParams = { owner_id: ownerId };
    let selectedDocuments = Object.values(selectedItems);
    const unselectedItemsArray = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedDocuments = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItemsArray, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to change the owner of these ${selectedItemsCount} documents to ${owner.label}?`,
      callback: (ok) => {
        if (ok) {
          DocumentActions.bulkUpdateDocuments(table, selectedDocuments, updateParams, query);
          BulkSelectActions.deselectAllItems(table);
        }
      },
    });
  }

  onDocumentRelatedContactsChange = (relatedContacts) => {
    const contactIds = relatedContacts.filter((contact) => contact != null);
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, query } = this.props;

    let updateParams = { related_contact_ids: contactIds };
    let selectedDocuments = Object.values(selectedItems);
    const unselectedItemsArray = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedDocuments = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItemsArray, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to change the related contacts of these ${selectedItemsCount} documents?`,
      callback: (ok) => {
        if (ok) {
          DocumentActions.bulkUpdateDocuments(table, selectedDocuments, updateParams, query);
          BulkSelectActions.deselectAllItems(table);
        }
      },
    });
  };

  onBulkDeleteDocuments = () => {
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const {
      table, query, records, ownerScope,
    } = this.props;

    let deleteParams = {};
    let selectedDocuments = Object.values(selectedItems);
    const unselectedItemsArray = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedDocuments = 'all';
      deleteParams = { ...deleteParams, unselected_ids: _lodash.map(unselectedItemsArray, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to delete these ${selectedItemsCount} documents?`,
      callback: (ok) => {
        if (ok) {
          const { page, s, per: size } = query;
          const searchOptions = { s };
          DocumentActions.bulkDeleteDocuments(table, selectedDocuments, deleteParams, searchOptions);
          BulkSelectActions.deselectAllItems(table);
          setTimeout(() => {
            DocumentActions.loadDocuments(table, { page, size, searchData: s });
          }, 500);
        }
      },
    });
  };

  onBulkRestoreDocuments = () => {
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, query, records } = this.props;

    let restoreParams = {};
    let selectedTrashDocuments = Object.values(selectedItems);
    const unselectedItemsArray = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedTrashDocuments = 'all';
      restoreParams = { ...restoreParams, unselected_ids: _lodash.map(unselectedItemsArray, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to restore these ${selectedItemsCount} documents?`,
      callback: (ok) => {
        if (ok) {
          checkIfLimitExceeded(selectedItemsCount).then((isLimitExceeded) => {
            if (isLimitExceeded) {
              this.setShowLimitAlert(true);
            } else {
              DocumentActions.bulkRestoreTrashDocuments(table, selectedTrashDocuments, restoreParams);
              BulkSelectActions.deselectAllItems(table);
              const { page, per: size, s: searchData } = query;
              setTimeout(() => {
                DocumentActions.loadTrashedDocuments(table, { page, size, searchData });
              }, 500);
            }
          });
        }
      },
    });
  };

  onBulkDeleteTrashDocuments = () => {
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, query } = this.props;

    let deleteParams = {};
    let selectedTrashDocuments = Object.values(selectedItems);
    const unselectedItemsArray = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedTrashDocuments = 'all';
      deleteParams = { ...deleteParams, unselected_ids: _lodash.map(unselectedItemsArray, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to permanently delete these ${selectedItemsCount} documents? This action cannot be undone.`,
      callback: (ok) => {
        if (ok) {
          DocumentActions.bulkDeleteTrashDocuments(table, selectedTrashDocuments, deleteParams);
          BulkSelectActions.deselectAllItems(table);
          const { page, per: size, s: searchData } = query;
          setTimeout(() => {
            DocumentActions.loadTrashedDocuments(table, { page, size, searchData });
          }, 500);
        }
      },
    });
  };

  onTaskOwnerChange(owner) {
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, records, query } = this.props;
    const searchData = Object.assign(query);

    let updateParams = { owner_id: owner.value };
    let selectedTasks = Object.values(selectedItems);
    const unselectedItems = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedTasks = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItems, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to change the owner of these ${selectedItemsCount} contacts to ${owner.label}?`,
      callback: (ok) => {
        if (ok) {
          AgentActions.bulkUpdateTasks(table, selectedTasks, searchData, updateParams);
          this.reloadLeads();
        }
      },
    });
  }

  onTaskStatusChange(status) {
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, records, query } = this.props;
    const searchData = Object.assign(query);

    let updateParams = { status: status.value };
    let selectedTasks = Object.values(selectedItems);
    const unselectedItems = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedTasks = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItems, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to change the status of these ${selectedItemsCount} contacts to ${status.label}?`,
      callback: (ok) => {
        if (ok) {
          AgentActions.bulkUpdateTasks(table, selectedTasks, searchData, updateParams);
          this.reloadLeads();
        }
      },
    });
  }

  onTaskCategoryChange(category) {
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, records, query } = this.props;
    const searchData = Object.assign(query);

    let updateParams = { category: category.value };
    let selectedTasks = Object.values(selectedItems);
    const unselectedItems = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedTasks = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItems, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to change the category of these ${selectedItemsCount} contacts to ${category.label}?`,
      callback: (ok) => {
        if (ok) {
          AgentActions.bulkUpdateTasks(table, selectedTasks, searchData, updateParams);
          this.reloadLeads();
        }
      },
    });
  }

  onTaskPriorityChange(priority) {
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, records, query } = this.props;
    const searchData = Object.assign(query);

    let updateParams = { priority: priority.value };
    let selectedTasks = Object.values(selectedItems);
    const unselectedItems = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedTasks = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItems, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to change the priority of these ${selectedItemsCount} contacts to ${priority.label}?`,
      callback: (ok) => {
        if (ok) {
          AgentActions.bulkUpdateTasks(table, selectedTasks, searchData, updateParams);
          this.reloadLeads();
        }
      },
    });
  }

  onTaskDueDateChange(dueDate) {
    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, records, query } = this.props;
    const searchData = Object.assign(query);

    let momentDueDate;

    if (Moment.isMoment(dueDate)) {
      momentDueDate = dueDate;
    } else if (dueDate && dueDate.date && Moment.isMoment(dueDate.date)) {
      momentDueDate = dueDate.date;
    } else if (dueDate instanceof Date) {
      momentDueDate = Moment(dueDate);
    } else {
      console.error('Invalid dueDate format:', dueDate);
      return;
    }

    let updateParams = { due_date_at: momentDueDate.format('YYYY-MM-DD') };
    let selectedTasks = Object.values(selectedItems);
    const unselectedItems = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedTasks = 'all';
      updateParams = { ...updateParams, unselected_ids: _lodash.map(unselectedItems, 'id') };
    }

    const snoozeDate = momentDueDate.format('dddd, MMMM Do');
    const selectedItemsCount = this.selectedItemsCount();

    brokerbit.confirmBox({
      message:  `Are you sure you want to change the due date on these ${selectedItemsCount} contacts to ${snoozeDate}?`,
      callback: (ok) => {
        if (ok) {
          AgentActions.bulkUpdateTasks(table, selectedTasks, searchData, updateParams);
          this.reloadLeads();
        }
      },
    });
  }

  onDeleteTaskClick(e) {
    e.preventDefault();

    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, query } = this.props;
    const searchData = Object.assign(query);

    let deleteData;
    let selectedTasks = Object.values(selectedItems);
    const unselectedItems = Object.values(unSelectedItems);

    if (selectAllItems) {
      selectedTasks = 'all';
      deleteData = { ...deleteData, unselected_ids: _lodash.map(unselectedItems, 'id') };
    }

    const selectedItemsCount = this.selectedItemsCount();
    const selectedItemsText = pluralize('record', selectedItemsCount, true);
    const pluralPronoun = pluralize('this', selectedItemsCount);

    brokerbit.confirmBox({
      message:  `Deleting ${pluralPronoun} ${selectedItemsText} will be permanent. Please confirm you want to proceed.`,
      callback: (ok) => {
        if (ok) {
          AgentActions.bulkDeleteTasks(table, selectedTasks, searchData, deleteData);
          BulkSelectActions.deselectAllItems(table);
          this.reloadLeads();
        }
      },
    });
  }

  onTaskDescriptionClick(e) {
    e.preventDefault();

    const { selectAllItems, unSelectedItems, selectedItems } = this.state;
    const { table, records, query } = this.props;
    const searchData = Object.assign(query);

    ReactDOM.render(
      <EditTaskDescriptionModal
        reloadLeads={this.reloadLeads}
        bulkSelecting={selectAllItems}
        table={table}
        records={records}
        searchData={searchData}
        selectedItems={selectedItems}
        unSelectedItems={unSelectedItems}
      />,
      document.getElementById('primary-modal'),
    );
  }

  setCategories = (categories) => {
    this.setState({ categories });
  };

  setStatuses = (statuses) => {
    this.setState({ statuses });
  };

  setShowLimitAlert = (showLimitAlert) => {
    this.setState({ showLimitAlert });
  };

  selectedItemsCount = () => {
    const { records } = this.props;
    const { selectedItems, selectAllItems, unSelectedItems } = this.state;

    let itemsCount;
    const total_count = records?.pagination.total_count;
    if (selectAllItems) {
      itemsCount = total_count - Object.keys(unSelectedItems).length;
    } else {
      itemsCount = Object.keys(selectedItems).length;
    }

    return itemsCount;
  }

  buildPageHref(pageNumber) {
    return `#page-${pageNumber}`;
  }

  reloadLeads(extraFilters = {}, pagination = {}, reloadTable = null, searchDataParams = true) {
    const {
      table, records, ownerScope, leadScopeSize,
    } = this.props;
    const { tableSortField, tableSortDirection, tableFilters } = this.state;

    const params = GlobalContainer.urlParams();

    const searchData = searchDataParams ? ({ ...params.s, ...tableFilters, ...extraFilters }) : {};

    this.setState({ fetching: true }, () => {
      AgentActions.loadLeads(reloadTable || table, {
        ownerScope,
        page:          pagination.page || records.pagination?.current_page,
        size:          pagination.size || leadScopeSize,
        sortColumn:    pagination.column || tableSortField.column,
        sortDirection: pagination.direction || tableSortDirection,
        searchData,
      });
    });
  }

  renderTableHeader() {
    const { table } = this.props;
    const { scope } = table;

    const renderHeader = () => {
      if (scope === 'today_or_past_tasks'
            || scope === 'tasks_due_later'
            || scope === 'tasks_overdue'
            || scope === 'all_tasks') {
        return this.renderTaskHeader();
      }

      if (scope === 'documents') {
        return this.renderDocumentHeader();
      }

      if (scope === 'trash_documents') {
        return this.renderTrashHeader();
      }

      return this.renderLeadHeader();
    };

    return (
      <tr className="no-hover table-header">
        {this.renderBulkSelector()}
        {renderHeader()}
      </tr>
    );
  }

  renderHeader() {
    const { loading } = this.props;
    const { selectedItems, selectAllItems } = this.state;

    if (!loading && (Object.keys(selectedItems).length > 0 || selectAllItems)) {
      return this.renderTableHeaderBulkForm();
    }

    return this.renderTableHeader();
  }

  renderTrash() {
    const {
      table, query, clickable, loading, records,
    } = this.props;
    const { selectedItems, selectAllItems } = this.state;
    const { timestamp } = table;

    if (loading) {
      return (
        <tr>
          <td colSpan={8}>
            <p className="text-center p-5">
              <FontAwesomeIcon icon="spinner" pulse size="2x" />
            </p>
          </td>
        </tr>
      );
    }
    const trashDocuments = records.documents || records.data;

    if (!trashDocuments || trashDocuments.length === 0) {
      return (
        <tr>
          <td colSpan={8}>
            <p className="text-center p-5">No documents in trash</p>
          </td>
        </tr>
      );
    }
    const selectedKeys = Object.keys(selectedItems);
    const trashRows = [];

    trashDocuments.forEach((document, idx) => {
      const trashItem = (
        <DataTableItem
          clickable={clickable}
          key={`trash-item-${document.id}`}
          trash_document={document}
          query={query}
          rowIdx={idx}
          selectedKeys={selectedKeys}
          selectAllItems={selectAllItems}
          table={table}
          totalCount={records.pagination.total_count}
          timestampField={timestamp.column}
        />
      );

      trashRows.push(trashItem);
    });

    return trashRows;
  }

  renderDocuments() {
    const {
      table, query, clickable, loading, records,
    } = this.props;
    const { selectedItems, selectAllItems } = this.state;
    const { timestamp } = table;
    if (loading) {
      return (
        <tr>
          <td colSpan={8}>
            <p className="text-center p-5">
              <FontAwesomeIcon icon="spinner" pulse size="2x" />
            </p>
          </td>
        </tr>
      );
    }
    const documents = records.documents || records.data;

    if (!documents || documents.length === 0) {
      return (
        <tr>
          <td colSpan={8}>
            <p className="text-center p-5">No documents found</p>
          </td>
        </tr>
      );
    }
    const selectedKeys = Object.keys(selectedItems);
    const documentRows = [];

    documents.forEach((document, idx) => {
      const documentItem = (
        <DataTableItem
          clickable={clickable}
          key={`document-item-${document.id}`}
          document={document}
          query={query}
          rowIdx={idx}
          selectedKeys={selectedKeys}
          selectAllItems={selectAllItems}
          table={table}
          totalCount={records.pagination.total_count}
          timestampField={timestamp.column}
        />
      );

      documentRows.push(documentItem);
    });

    return documentRows;
  }

  renderLeads() {
    const {
      table, query, clickable, loading, records,
    } = this.props;
    const { selectedItems, selectAllItems } = this.state;
    const { timestamp } = table;

    if (loading) {
      return (
        <tr>
          <td colSpan={8}>
            <p className="text-center p-5">
              <FontAwesomeIcon icon="far fa-spinner" pulse size="2x" />
            </p>
          </td>
        </tr>
      );
    }

    // If error occurred, show that.
    if (records.error) {
      return (
        <tr>
          <td colSpan={8}>
            <p className="text-center p-5">{records.error}</p>
          </td>
        </tr>
      );
    }

    // don't show spinner when data is null to avoid user mistaken slow loading
    if (_lodash.isEmpty(records.data)) {
      return (
        <tr>
          <td colSpan={8}>
            <p className="text-center p-5">No data found</p>
          </td>
        </tr>
      );
    }

    const selectedKeys = Object.keys(selectedItems);
    const leadRows = [];

    records.data.forEach((lead, idx) => {
      const leadItem = (
        <DataTableItem
          clickable={clickable}
          key={`lead-item-${lead.id}`}
          lead={lead}
          query={query}
          rowIdx={idx}
          selectedKeys={selectedKeys}
          selectAllItems={selectAllItems}
          table={table}
          totalCount={records.pagination.total_count}
          timestampField={timestamp.column}
        />
      );

      leadRows.push(leadItem);
    });

    return leadRows;
  }

  renderPagination() {
    const { table, records, loading } = this.props;
    const { tableFilters } = this.state;
    const entity = scopeToEntity[table.scope] || 'leads';
    const { pagination } = records;

    if (!records || loading) {
      return null;
    }

    const totalCount = pagination.total_count;
    const totalPages = pagination.total_pages;
    const currentPage = pagination.current_page;
    const { from } = pagination;
    const { to } = pagination;

    return (
      <>
        {!!table.enableProductionFilter && (
          <div className="pl0 pr0 pt10 pb10 d-flex flex-column flex-md-row justify-content-between align-items-center">
            <DataTableProductionFilter
              table={table}
              productions={tableFilters.production}
              onChange={this.onFilteringProductions}
            />
          </div>
        )}

        <div className="pl0 pr0 pt10 pb10 d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div className="pull-left">
            <span className="pr10">
              Showing
              {' '}
              {from}
              {' '}
              to
              {' '}
              {to}
              {' '}
              of
              {' '}
              {totalCount}
              {' '}
              {entity}
            </span>
          </div>

          {totalPages > 1 && (
            <div className="pull-right mt-3 mt-md-0">
              <nav>
                <ReactPaginate
                  pageCount={totalPages}
                  forcePage={currentPage - 1}
                  pageRangeDisplayed={Rails.isMobile ? 1 : 3}
                  marginPagesDisplayed={Rails.isMobile ? 2 : 3}
                  containerClassName="pagination"
                  activeClassName="active"
                  breakLabel="..."
                  breakLinkClassName="page-link disabled"
                  breakClassName="page-item"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLabel={Rails.isMobile ? '<' : 'Previous'}
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLabel={Rails.isMobile ? '>' : 'Next'}
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  onPageChange={this.onPageChange}
                  hrefBuilder={this.buildPageHref}
                />
              </nav>
            </div>
          )}
        </div>
      </>
    );
  }

  renderNoLeadsMessage() {
    const { table } = this.props;
    const { tableFilters } = this.state;
    const entity = scopeToEntity[table.scope] || 'leads';

    if (Rails.abilities.manageLead) {
      return (
        <div className="text-center p30">
          <p className="lead mb5">
            No
            {' '}
            {entity}
            {' '}
            here, folks
          </p>
          <p>
            Congrats, if you&#39;re done for the day! Otherwise, change the filter to
            {' '}
            <strong className="text-underline">All</strong>
            {' '}
            to see everyone&#39;s
            {' '}
            {entity}
            {' '}
            and assign some to you.
          </p>

          {!!table.enableProductionFilter && (
            <span>
              <hr />

              <DataTableProductionFilter
                table={table}
                productions={tableFilters.production}
                onChange={this.onFilteringProductions}
              />
            </span>
          )}
        </div>
      );
    } if (table.scope === 'agent_referrals') {
      return (
        <div className="text-center p30">
          <p className="lead mb5">No referrals yet</p>
          <p>You&#39;re in, let&#39;s get started referring!</p>
        </div>
      );
    }

    return null;
  }

  renderLeadHeader() {
    const { tableSortField, tableSortDirection } = this.state;
    const { table } = this.props;
    const { timestamp, scope } = table;

    return (
      <>
        {GlobalContainer.product() !== 'retention' && (
          <th width="15%" className="pt20 d-none d-sm-table-cell">
            <DataTableSortLink
              sortField={{ column: 'status', title: 'Status' }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}

        <th width="25%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'last_name', title: 'Name' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>

        <th width="15%" className="pt20 d-none d-md-table-cell">
          <DataTableSortLink
            sortField={{ column: 'production_ytd', title: 'Total $ Prod LTM' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>

        {GlobalContainer.product() === 'retention' && (
          <th width="15%" className="pt20 d-none d-md-table-cell">
            <DataTableSortLink
              sortField={{ column: 'referrals_count', title: '# Referrals' }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}

        {GlobalContainer.product() === 'retention' && (
          <th width="10%" className="pt20 d-none d-sm-table-cell">
            <DataTableSortLink
              sortField={{ column: 'anniversary_month', title: 'Anniversary' }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}

        {GlobalContainer.product() === 'retention' && (
          <th width="10%" className="pt20 d-none d-sm-table-cell">
            <DataTableSortLink
              sortField={{ column: 'birthday', title: 'Birthday' }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}

        {GlobalContainer.product() !== 'retention' && (
          <th width="15%" className="pt20 d-none d-md-table-cell">
            <DataTableSortLink
              sortField={{ column: 'currently_at', title: 'Company' }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}

        {timestamp.column && (
          <th width="10%" className="pt20 d-none d-lg-table-cell">
            <DataTableSortLink
              sortField={{ column: timestamp.column, title: timestamp.title }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}

        {Rails.helpers.currentUser.staff_type === 'external' && (
          <th className="pt20 d-none d-lg-table-cell">
            <a href="#last_activity" className="sort_link" onClick={(e) => e.preventDefault()}>
              Last Activity
            </a>
          </th>
        )}

        {GlobalContainer.product() === 'retention' && (
          <th width="15%" className="pt20 d-none d-sm-table-cell">
            <DataTableSortLink
              sortField={{ column: 'my_last_activity_at', title: 'Last Contact From Me' }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}

        {GlobalContainer.product() === 'recruiting' && scope === 'search_results' && (
          <th width="10%" className="pt20 d-none d-md-table-cell">
            FOLLOW UP
          </th>
        )}

        {Rails.abilities.manageLead && (
          <th className="pt20 text-center d-none d-md-table-cell">
            <DataTableSortLink
              sortField={{ column: 'owner_id', title: 'Owner' }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}
      </>
    );
  }

  renderTaskHeader() {
    const { tableSortField, tableSortDirection } = this.state;
    const { table } = this.props;
    const { timestamp, scope, leadTasks } = table;

    return (
      <>
        {(leadTasks && GlobalContainer.product() !== 'retention') && (
          <th width="15%" className="pt20">
            <DataTableSortLink
              sortField={{ column: 'lead_status', title: 'Status' }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}

        <th width="15%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'lead_last_name', title: 'Name' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>

        <th width="15%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'lead_production_ytd', title: 'Total $ Prod' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>

        {(leadTasks && GlobalContainer.product() !== 'retention') && (
          <th width="15%" className="pt20">
            <DataTableSortLink
              sortField={{ column: 'lead_company', title: 'Company' }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}

        <th width="15%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'task_name', title: 'Task Name' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>

        {(!leadTasks || GlobalContainer.product() === 'retention') && (
          <th width="5%" className="pt20">
            <DataTableSortLink
              sortField={{ column: 'task_status', title: 'Status' }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}

        {!leadTasks && (
          <th width="5%" className="pt20">
            <DataTableSortLink
              sortField={{ column: 'task_priority', title: 'Priority' }}
              onSortToggle={this.onSortToggle}
              tableSortField={tableSortField}
              tableSortDirection={tableSortDirection}
            />
          </th>
        )}

        <th width="10%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'task_category', title: 'Category' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>

        <th width="5%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'task_due_date_at', title: 'Due Date' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>

        <th width="5%" className="pt20 text-center d-none d-sm-table-cell">
          <DataTableSortLink
            sortField={{ column: 'task_owner_id', title: 'Task Owner' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>

        <th width="10%" className="pt20 text-center text-uppercase">
          Actions
        </th>
      </>
    );
  }

  renderTrashHeader() {
    const { tableSortField, tableSortDirection } = this.state;

    return (
      <>
        <th width="15%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'title', title: 'NAME' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
            sortDirection={tableSortDirection}
          />
        </th>
        <th width="15%" className="pt20 text-center d-none d-md-table-cell">
          <DataTableSortLink
            sortField={{ column: 'trashed_by', title: 'Deleted By' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
            sortDirection={tableSortDirection}
          />
        </th>
        <th width="15%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'trashed_at', title: 'Deleted At' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
            sortDirection={tableSortDirection}
          />
        </th>
        <th width="15%" className="pt20 text-center text-uppercase">
          Actions
        </th>
      </>
    );
  }

  renderDocumentHeader() {
    const { tableSortField, tableSortDirection } = this.state;
    return (
      <>
        <th width="15%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'title', title: 'NAME' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>
        <th width="15%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'status', title: 'STATUS' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>
        <th width="15%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'uploaded_at', title: 'Created' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
            sortDirection={tableSortDirection}
          />
        </th>
        <th width="15%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'size', title: 'Size' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>
        <th width="15%" className="pt20">
          <DataTableSortLink
            sortField={{ column: 'category', title: 'CATEGORY' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>
        <th width="15%" className="pt20 text-center d-none d-md-table-cell">
          <DataTableSortLink
            sortField={{ column: 'owner', title: 'Owner' }}
            onSortToggle={this.onSortToggle}
            tableSortField={tableSortField}
            tableSortDirection={tableSortDirection}
          />
        </th>
        <th width="15%" className="pt20">
          Related Contacts
        </th>
        <th width="10%" className="pt20 text-center text-uppercase">
          Actions
        </th>
      </>
    );
  }

  renderTableHeaderBulkForm() {
    const { records } = this.props;
    const { table } = this.props;
    const {
      contactsBulkAction = {},
      tasksBulkAction = {},
      documentBulkAction = {},
      trashDocumentBulkAction = {},
    } = table;
    const product = GlobalContainer.product();
    const labelName = product === 'retention' ? 'Agents' : 'Leads';
    const stage = LeadHelpers.determineStatusStageByProduct(product);
    const { categories, statuses, showLimitAlert } = this.state;
    const totalRecordsCount = records?.pagination?.total_count || 0;

    return (
      <tr className="no-hover table-header">
        {this.renderBulkSelector()}

        <th className="bulk-select-actions pl0 pr0" colSpan={10}>
          <form className="form-inline" action="#bulk-actions-form">
            <div className="col-lg-2 col-md-3 p0">
              <div className="mb-2">
                Selected
                {' '}
                <span className="count">{this.selectedItemsCount()}</span>
                {' '}
                of
                {' '}
                {totalRecordsCount}
                {' '}
                records
              </div>
              <div>
                <a onClick={this.onSelectAllRecords}>
                  Select all
                  {' '}
                  {totalRecordsCount}
                  {' '}
                  records
                </a>
              </div>
            </div>

            {/* Trash Document Bulk Actions */}

            {trashDocumentBulkAction.restore && (
              <DocumentLimitAlertChecker
                showLimitAlert={showLimitAlert}
                setShowLimitAlert={this.setShowLimitAlert}
              >
                <button type="button" className="btn btn-secondary mr5 mb-2" onClick={this.onBulkRestoreDocuments}>
                  <FontAwesomeIcon icon={['fa-light', 'trash-undo']} />
                  {' '}
                  Restore
                </button>
              </DocumentLimitAlertChecker>
            )}

            {trashDocumentBulkAction.permanent_delete && (
              <button type="button" className="btn btn-secondary mr5 mb-2" onClick={this.onBulkDeleteTrashDocuments}>
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
                {' '}
                Delete
              </button>
            )}

            {/* Document Bulk Actions */}

            {documentBulkAction.related_contacts && (
              <LeadsSelect
                placeholder="Add Related Contacts"
                className="form-control mr5 mb-3"
                onChange={this.onDocumentRelatedContactsChange}
                multiple
              />
            )}

            {documentBulkAction.status && (
              <select
                className="form-control ml5 mr5"
                onChange={(e) => this.onDocumentStatusChange({ value: e.target.value, label: e.target.options[e.target.selectedIndex].text })}
                placeholder="Change Status"
                style={{ minHeight: '37px' }}
              >
                <option value="">Select Status</option>
                {statuses.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </select>
            )}

            {documentBulkAction.category && (
            <select
              className="form-control mr5"
              onChange={(e) => this.onDocumentCategoryChange({ value: e.target.value, label: e.target.options[e.target.selectedIndex].text })}
              placeholder="Change Category"
              style={{ minHeight: '37px' }}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            )}

            {documentBulkAction.owner && (
              <TeammatePicker
                placeholder="Change Owner..."
                className="mr5"
                onChange={this.onDocumentOwnerChange}
                style={{ minWidth: '140px' }}
                apiResource="/v1/documents/owner_options"
              />
            )}

            {documentBulkAction.delete && (
              <button type="button" className="btn btn-secondary mr5" onClick={this.onBulkDeleteDocuments}>
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
                {' '}
                Delete
              </button>
            )}

            <div className="col-lg-10 col-md-9 pr0">
              <div className="row m0">
                {contactsBulkAction.labels && (
                  <div className="btn-group mb-2" role="group">
                    <button
                      id="btnGroupLabels"
                      type="button"
                      className="btn btn-secondary dropdown-toggle mr5"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon icon={['far', 'fa-tags']} />
                      {' '}
                      Labels
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupLabels">
                      <a className="dropdown-item" href="#add-label" onClick={this.onAddLabelClick}>
                        Add Label to
                        {' '}
                        {labelName}
                      </a>
                      <a className="dropdown-item" href="#remove-label" onClick={this.onRemoveLabelClick}>
                        Remove Label from
                        {' '}
                        {labelName}
                      </a>
                    </div>
                  </div>
                )}

                {contactsBulkAction.notes && (
                  <button type="button" className="btn btn-secondary mr5 mb-2" onClick={this.onAddNoteClick}>
                    <FontAwesomeIcon icon={['far', 'fa-sticky-note']} />
                    {' '}
                    Add Note
                  </button>
                )}

                {contactsBulkAction.emails && (
                  <button type="button" className="btn btn-secondary mr5 mb-2" onClick={this.onAddEmailClick}>
                    <FontAwesomeIcon icon={['far', 'fa-paper-plane']} />
                    {' '}
                    Send Email
                  </button>
                )}

                {(contactsBulkAction.texts && GlobalContainer.product() === 'retention') && (
                  <button type="button" className="btn btn-secondary mr5 mb-2" onClick={this.onAddSMSClick}>
                    <FontAwesomeIcon icon={['far', 'fa-bullhorn']} />
                    {' '}
                    Send Text
                  </button>
                )}

                {contactsBulkAction.campaigns && (
                  <div className="btn-group mb-2" role="group">
                    <button
                      id="btnGroupCampaigns"
                      type="button"
                      className="btn btn-secondary dropdown-toggle mr5"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Smart Campaigns
                    </button>
                    <div className="dropdown-menu mb-2" aria-labelledby="btnGroupCampaigns">
                      <a className="dropdown-item" href="#add-to-campaign" onClick={this.onAddCampaignClick}>
                        Add to Campaign
                      </a>
                      <a className="dropdown-item" href="#remove-from-campaign" onClick={this.onRemoveCampaignClick}>
                        Remove from Campaign
                      </a>
                    </div>
                  </div>
                )}

                {contactsBulkAction.tasks && (
                  <button type="button" className="btn btn-secondary mr5 mb-2" onClick={this.onAddTaskClick}>
                    <FontAwesomeIcon icon={['far', 'fa-tasks']} />
                    {' '}
                    Add Task
                  </button>
                )}

                {contactsBulkAction.delete && (
                  <button type="button" className="btn btn-secondary mr5 mb-2" onClick={this.onDeleteClick}>
                    <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
                    {' '}
                    Delete
                  </button>
                )}

                {contactsBulkAction.bucket && (
                  <BucketSelect
                    placeholder="Move to ..."
                    className="mr5 mb-2"
                    onChange={this.onBucketChange}
                    style={{ minWidth: '140px' }}
                  />
                )}

                {contactsBulkAction.owner && (
                  <TeammatePicker
                    placeholder="Change Owner..."
                    className="mr5 mb-2"
                    onChange={this.onOwnerChange}
                    style={{ minWidth: '140px' }}
                  />
                )}

                {contactsBulkAction.status && (
                  <div className="col-2 p0">
                    <StatusSelect
                      placeholder="Change Status ..."
                      stage={stage}
                      className="mr5 mb-2"
                      onChange={this.onStatusChange}
                      icSearchable
                    />
                  </div>
                )}

                {/* Tasks */}
                {tasksBulkAction.owner && (
                  <TeammatePicker
                    placeholder="Change Owner..."
                    className="mr5 mb-2"
                    onChange={this.onTaskOwnerChange}
                    style={{ minWidth: '140px' }}
                  />
                )}

                {tasksBulkAction.status
                  && (
                  <TaskStatusSelect
                    placeholder="Change Status..."
                    className="mr5 mb-2"
                    onChange={this.onTaskStatusChange}
                    style={{ minWidth: '140px' }}
                  />
                  )}

                {tasksBulkAction.category
                  && (
                  <TaskCategorySelect
                    placeholder="Change Category..."
                    className="mr5 mb-2"
                    onChange={this.onTaskCategoryChange}
                    style={{ minWidth: '140px' }}
                  />
                  )}

                {tasksBulkAction.priority
                  && (
                  <TaskPrioritySelect
                    placeholder="Change Priority..."
                    className="mr5 mb-2"
                    onChange={this.onTaskPriorityChange}
                    style={{ minWidth: '140px' }}
                  />
                  )}

                {tasksBulkAction.dueDate
                  && (
                  <div>
                    <DateTimePicker
                      onChange={this.onTaskDueDateChange}
                      minDate={Moment().toDate()}
                    >
                      <button type="button" className="btn btn-secondary mr5 mb-2">
                        <FontAwesomeIcon icon={['far', 'fa-calendar-alt']} />
                        {' '}
                        Change Due Date
                      </button>
                    </DateTimePicker>
                  </div>
                  )}

                {tasksBulkAction.description
                  && (
                  <button type="button" className="btn btn-secondary mr5 mb-2" onClick={this.onTaskDescriptionClick}>
                    <FontAwesomeIcon icon={['far', 'fa-pencil-alt']} />
                    {' '}
                    Change Description
                  </button>
                  )}

                {tasksBulkAction.deleteTask
                  && (
                  <button type="button" className="btn btn-secondary mr5 mb-2" onClick={this.onDeleteTaskClick}>
                    <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
                    {' '}
                    Delete Task
                  </button>
                  )}
              </div>
            </div>
          </form>
        </th>
      </tr>
    );
  }

  renderBulkSelector() {
    if (Rails.abilities.manageLead === false) return null;

    const id = uuidv4();
    const { records } = this.props;
    const { selectedItems, selectAllItems } = this.state;
    const selectedIds = _lodash.map(selectedItems, 'id');
    const data = records.data || records.documents || [];

    let checked = data.length > 0 && (data.every((item) => selectedIds.includes(item.id)) || selectAllItems);

    if (checked === undefined) {
      checked = false;
    }

    return (
      <th className="d-md-table-cell text-center" width="1%">
        <div className="custom-control custom-checkbox mt5">
          <input
            type="checkbox"
            className="custom-control-input"
            id={id}
            checked={checked}
            onChange={this.onBulkSelect}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="custom-control-label custom-control-heading-label" htmlFor={id} />
        </div>
      </th>
    );
  }

  renderTaskTabs() {
    const { table } = this.props;
    const params = GlobalContainer.urlParams();
    const { tableSortField, tableSortDirection, tableFilters } = this.state;

    const tasksDueTodaySelected = table.scope === 'today_or_past_tasks';
    const tasksDueLaterSelected = table.scope === 'tasks_due_later';
    const tasksOverdueSelected  = table.scope === 'tasks_overdue';
    const allTasksSelected      = table.scope === 'all_tasks';

    const _url = (resource, extras = {}) => {
      const searchData = { ...params.s, ...tableFilters, ...extras };
      const product = GlobalContainer.product() === 'recruiting' ? 'recruiter' : 'retention';
      const searchQuery = qs.stringify(
        { s: { ...searchData } },
        { arrayFormat: 'brackets' },
      );

      return `/${product}/${resource}?${decodeURI(searchQuery)}`;
    };

    return (
      <Nav tabs>
        <NavItem className="pl5 pr5">
          <Link
            to={_url('tasks_due_today')}
            className={classNames('nav-link ml20', { active: tasksDueTodaySelected })}
          >
            Tasks Due Today
          </Link>
        </NavItem>

        <NavItem className="pl5 pr5">
          <Link
            to={_url('tasks_due_later')}
            className={classNames('nav-link', { active: tasksDueLaterSelected })}
          >
            Tasks Due Later
          </Link>
        </NavItem>
        <NavItem className="pl5 pr5">
          <Link
            to={_url('tasks_overdue')}
            className={classNames('nav-link', { active: tasksOverdueSelected })}
          >
            Tasks Overdue
          </Link>
        </NavItem>
        <NavItem className="pl5 pr5">
          <Link
            to={_url('tasks')}
            className={classNames('nav-link', { active: allTasksSelected })}
          >
            All Tasks
          </Link>
        </NavItem>
      </Nav>
    );
  }

  renderTabs() {
    const { table, loading, records } = this.props;

    const params = GlobalContainer.urlParams();
    const { tableSortField, tableSortDirection, tableFilters } = this.state;

    const recruitingSelected = GlobalContainer.product() === 'recruiting' && (table.scope === 'search_results' || table.scope === 'recruiting_leads');
    const retentionSelected  = GlobalContainer.product() === 'retention' && (table.scope === 'search_results' || table.scope === 'retention_team');
    const archivedSelected   = (params.s && params.s.archived === 'true') || table.scope === 'archived';

    const _url = (resource, extras = {}) => {
      const searchData = { ...params.s, ...tableFilters, ...extras };
      const searchQuery = qs.stringify(
        { s: { ...searchData, query: encodeURIComponent(searchData.query) }, _t: Rails.helpers.currentTeam.id },
        { arrayFormat: 'brackets' },
      );
      return `/${resource}/search?${decodeURI(searchQuery)}`;
    };

    const contactsUrl = (resource) => `/${resource}`;

    const contactScope = table.scope === 'recruiting_leads' || table.scope === 'retention_team' || table.scope === 'archived';

    return (
      <Nav tabs>
        <NavItem className="pl5 pr5">
          <Link
            to={contactScope ? contactsUrl('recruiter/leads') : _url('recruiter', { archived: false })}
            className={classNames('nav-link ml20', { active: recruitingSelected })}
          >
            Recruiting
            {!loading && (
              <span className={classNames('badge badge-pill ml5', `badge-${GlobalContainer.productColor()}`)}>{records.recruiting_count}</span>
            )}
          </Link>
        </NavItem>
        <NavItem className="pl5 pr5">
          <Link
            to={contactScope ? contactsUrl('retention/team') : _url('retention', { archived: false })}
            className={classNames('nav-link', { active: retentionSelected })}
          >
            Retention
            {!loading && (
              <span className={classNames('badge badge-pill ml5', `badge-${GlobalContainer.productColor()}`)}>{records.retention_count}</span>
            )}
          </Link>
        </NavItem>
        <NavItem className="pl5 pr5">
          <Link
            to={contactScope ? contactsUrl('archived') : _url('archived', { archived: true })}
            className={classNames('nav-link', { active: archivedSelected })}
          >
            Archived
            {!loading && (
              <span className={classNames('badge badge-pill ml5', `badge-${GlobalContainer.productColor()}`)}>{records.archived_count}</span>
            )}
          </Link>
        </NavItem>
      </Nav>
    );
  }

  renderViewTrashButton() {
    const context = GlobalContainer.product() === 'recruiting' ? 'recruiter' : GlobalContainer.product();

    return (
      <div style={{ position: 'absolute', right: '20px', bottom: '-50px' }}>
        <Link to={`/${context}/documents/trash`} className="btn btn-secondary">
          View Trash
        </Link>
      </div>
    );
  }

  render() {
    const { table, records, loading } = this.props;
    const { scope } = table;
    const data = records.data || records.documents || [];

    const renderContent = () => {
      if (scope === 'documents') {
        return this.renderDocuments();
      }
      if (scope === 'trash_documents') {
        return this.renderTrash();
      }
      return this.renderLeads();
    };

    if (data.length === 0 && loading === false) {
      return (
        <div>
          {table.tabs && this.renderTabs()}
          {table.task_tabs && this.renderTaskTabs()}

          <div className="remote pl10 pr10">
            {this.renderNoLeadsMessage()}
          </div>
          {scope === 'documents' && (this.renderViewTrashButton())}
        </div>
      );
    }

    return (
      <div>
        {table.tabs && this.renderTabs()}
        {table.task_tabs && this.renderTaskTabs()}

        <div className="table-responsive width-calc ml-md-3 mr-md-3 ml-lg-0 mr-lg-0">
          <table className="table table-hover leads-table mb0 no-border">
            <tbody>
              {this.renderHeader()}
              {renderContent()}
            </tbody>
          </table>
        </div>

        <div className="card-block">{this.renderPagination()}</div>

        {scope === 'documents' && (this.renderViewTrashButton())}
      </div>
    );
  }
}

DataTableContent.contextTypes = {
  handleSearch:     PropTypes.func,
  handleTaskSearch: PropTypes.func,
};

DataTableContent.defaultProps = {
  table:          {},
  query:          {},
  clickable:      true,
  loading:        false,
  records:        {},
  ownerScope:     'all',
  leadScopeSize:  10,
  tableSortField: {
    column: 'id',
    title:  'ID',
  },
  tableSortDirection: null,
  timestampField:     'referred_at',
};

DataTableContent.propTypes = {
  table: PropTypes.shape({
    timestamp: PropTypes.shape({}),
  }),
  query:              PropTypes.shape({}),
  clickable:          PropTypes.bool,
  loading:            PropTypes.bool,
  records:            PropTypes.shape({}),
  ownerScope:         PropTypes.string,
  leadScopeSize:      PropTypes.number,
  tableSortField:     PropTypes.shape({}),
  tableSortDirection: PropTypes.string,
  timestampField:     PropTypes.string,
};

export default DataTableContent;

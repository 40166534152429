import React, {
  useRef,
  useEffect,
  useMemo,
} from 'react';
import {
  TempusDominus,
  DateTime,
  Namespace,
} from '@eonasdan/tempus-dominus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes }       from 'prop-types';
import { v4 as uuidv4 }    from 'uuid';
import classnames          from 'classnames';

const getIcon = (pickerType) => (pickerType === 'time' ? 'far fa-clock' : 'far fa-calendar-alt');
const isSameDate = (date1, date2, format) => {
  if (!date1 || !date2) return false;

  const date1Formatted = date1.format(format);
  const date2Formatted = date2.format(format);

  return date1Formatted === date2Formatted;
};

const DateTimePicker = ({
  id,
  format,
  value,
  placeholder,
  pickerType,
  bulk,
  showTodayButton,
  showClear,
  useCurrent,
  isInvalid,
  isDisabled,
  minDate,
  maxDate,
  onChange,
  children,
}) => {
  const pickerElement = useRef(null);
  const controlRef = useRef(null);
  const faIcon = useMemo(() => getIcon(pickerType), [pickerType]);

  useEffect(() => {
    if (pickerElement.current) {
      controlRef.current = new TempusDominus(pickerElement.current, {
        useCurrent,
        localization: {
          locale: 'en-US',
        },
        restrictions: {
          minDate,
          maxDate,
        },
        display: {
          theme:    'light',
          buttons:  {
            clear: showClear,
            today: showTodayButton,
          },
          components: {
            decades: false,
            year:    pickerType !== 'time',
            month:   pickerType !== 'time',
            date:    pickerType !== 'time',
            hours:   pickerType !== 'date',
            minutes: pickerType !== 'date',
            seconds: pickerType === 'datetime',
          },
          icons:      {
            time:     'far fa-clock',
            date:     'far fa-calendar',
            up:       'far fa-arrow-up',
            down:     'far fa-arrow-down',
            next:     'far fa-arrow-right',
            previous: 'far fa-arrow-left',
            today:    'far fa-calendar-check',
            clear:    'far fa-trash',
            close:    'far fa-times',
          },
        },
      });

      controlRef.current.dates.formatInput = (date) => (date ? Moment(date).format(format) : '');

      controlRef.current.subscribe(Namespace.events.change, (e) => {
        onChange(e.date ? Moment(e.date) : null);
      });
    }

    return () => controlRef.current?.dispose();
  }, [pickerElement.current]);

  useEffect(() => {
    const momentValue = Moment(value, format);
    const newDate = value && momentValue.isValid() ? momentValue : null;

    if (controlRef.current && newDate) {
      const current = controlRef.current.dates.lastPicked ? Moment(controlRef.current.dates.lastPicked) : null;

      if (!current || !isSameDate(newDate, current, format)) {
        controlRef.current.dates.setFromInput(DateTime.convert(newDate.toDate()));

        const input = pickerElement.current.querySelector('input');
        if (input) {
          input.value = newDate.format(format);
        }
      }
    }
  }, [value, format]);

  const renderTrigger = () => {
    if (children) {
      return (
        <div onClick={() => pickerElement?.current?.show}>
          {children}
        </div>
      );
    }

    return (
      <div className="input-group">
        <input
          id={id || uuidv4()}
          disabled={isDisabled}
          type="text"
          className={classnames('form-control', { 'has-error': isInvalid })}
          placeholder={placeholder}
          onKeyDown={(e) => e.preventDefault()}
        />
        <div className="input-group-append">
          <span className="input-group-text">
            <FontAwesomeIcon icon={faIcon} />
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={bulk ? '' : 'position-relative col p0'} ref={pickerElement}>
      {renderTrigger()}
    </div>
  );
};

DateTimePicker.propTypes = {
  id:              PropTypes.string,
  format:          PropTypes.string,
  value:           PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  placeholder:     PropTypes.string,
  pickerType:      PropTypes.string,
  bulk:            PropTypes.bool,
  showTodayButton: PropTypes.bool,
  showClear:       PropTypes.bool,
  useCurrent:      PropTypes.bool,
  isInvalid:       PropTypes.bool,
  isDisabled:      PropTypes.bool,
  minDate:         PropTypes.instanceOf(Date),
  maxDate:         PropTypes.instanceOf(Date),
  onChange:        PropTypes.func,
  children:        PropTypes.node,
};

DateTimePicker.defaultProps = {
  id:              '',
  format:          'LL',
  value:           '',
  placeholder:     '',
  pickerType:      'datetime',
  bulk:            false,
  showTodayButton: false,
  showClear:       false,
  useCurrent:      false,
  isInvalid:       false,
  isDisabled:      false,
  minDate:         undefined,
  maxDate:         undefined,
  onChange:        () => {},
  children:        null,
};

export default DateTimePicker;

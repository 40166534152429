import classNames          from 'classnames';
import autoBind            from 'react-autobind';
import React               from 'react';
import ReactDOM            from 'react-dom';
import { PropTypes }       from 'prop-types';
import { v4 as uuidv4 }    from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit            from '~/lib/brokerbit';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import { ALL_STATUSES }     from '~/components/forms/dropdowns/StatusSelect';
import Tooltipable          from '~/components/effects/tooltipable';
import BulkSelectActions    from '~/actions/bulk_select_actions';
import TaskActions          from '~/actions/task_actions';
import LeadHelpers          from '~/helpers/lead_helpers';
import UserAvatar           from '~/components/shared/users/user_avatar';
import { months }           from '~/components/forms/simple_month_picker';
import TaskModal            from '~/components/modals/task_modal';

import DocumentActions           from '~/actions/document_actions';
import DocumentStore             from '~/stores/document_store';
import EditDocumentModal         from '~/components/modals/edit_document_modal';
import DocumentLimitAlertChecker from '~/components/shared/document_limit_alert_checker';

import {
  formatedFileSize, formatedDate, checkIfLimitExceeded,
} from '~/helpers/document_helpers';

class DataTableItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow:    false,
      showLimitAlert: false,
    };

    autoBind(this);
  }

  componentDidMount() {
    const {
      selectedKeys, lead, table, document, trash_document,
    } = this.props;
    let selectedRow = false;
    if (table.scope === 'documents' && document) {
      selectedRow = _lodash.includes(selectedKeys, `document-item-${document.id}`);
    } else if (table.scope === 'trash_documents' && trash_document) {
      selectedRow = _lodash.includes(selectedKeys, `trash-document-${trash_document.id}`);
    } else if (lead) {
      selectedRow = _lodash.includes(selectedKeys, `lead-item-${lead.id}`);
    }

    this.setState({ selectedRow });
    this.unsubscribe = DocumentStore.addListener(this.onChange);
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe.remove();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      selectedKeys, lead, table, document, trash_document,
    } = props;
    let selectedRow = false;

    if (table.scope === 'documents' && document) {
      selectedRow = _lodash.includes(selectedKeys, `document-item-${document.id}`);
    } else if (table.scope === 'trash_documents' && trash_document) {
      selectedRow = _lodash.includes(selectedKeys, `trash-document-${trash_document.id}`);
    } else if (lead) {
      selectedRow = _lodash.includes(selectedKeys, `lead-item-${lead.id}`);
    }

    state.selectedRow = selectedRow;

    return state;
  }

  handleEditDocumentClick = (e, document) => {
    const { table, query } = this.props;
    e.preventDefault();

    ReactDOM.render(
      <EditDocumentModal
        documentID={document.id}
        containerID="secondary-modal"
        modalClass="modal modal-overlay"
        dialogClass="modal-dialog modal-dialog-centered"
        onClose={this.handleCloseModal}
        table={table}
        query={query}
      />,
      window.document.getElementById('secondary-modal'),
    );
  };

  handleCloseModal = () => {
    const modalContainer = document.getElementById('secondary-modal');

    if (modalContainer) {
      ReactDOM.unmountComponentAtNode(modalContainer);
    }
  };

  handleDocumentDelete = (e, document) => {
    e.preventDefault();
    const { table, query } = this.props;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this document?',
      callback: (ok) => {
        if (ok) {
          DocumentActions.deleteDocument(document, null, table, query);
        }
      },
    });
  };

  handleChangeInternalClick = (document) => {
    const { internal } = document;
    const { table, query } = this.props;

    DocumentActions.updateDocument({
      ...document,
      internal: !internal,
    }, null, table, query);
  };

  handleDownloadDocument(e, document) {
    e.preventDefault();
    try {
      const downloadUrl = `${Rails.apiUrl}/api/v1/documents/${document.id}/download?auth=${Rails.helpers.authToken}`;

      const link = window.document.createElement('a');
      link.href = downloadUrl;
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  }

  handleRestoreDocument = (e, trash_document) => {
    e.preventDefault();
    const { table, query } = this.props;

    brokerbit.confirmBox({
      message:  'Are you sure you want to restore this document?',
      callback: (ok) => {
        if (ok) {
          checkIfLimitExceeded(1).then((isLimitExceeded) => {
            if (isLimitExceeded) {
              this.setShowLimitAlert(true);
            } else {
              DocumentActions.restoreDocument(trash_document.id, query?.page);
              BulkSelectActions.deselectTrashDocumentItem(table, trash_document);
            }
          });
        }
      },
    });
  };

  handlePermanentDeleteDocument = (e, trash_document) => {
    e.preventDefault();
    const { table, query } = this.props;

    brokerbit.confirmBox({
      message:  'Are you sure you want to permanently delete this document?',
      callback: (ok) => {
        if (ok) {
          DocumentActions.permanentDeleteDocument(trash_document.id, query?.page);
          BulkSelectActions.deselectTrashDocumentItem(table, trash_document);
        }
      },
    });
  };

  handleMarkAsDoneTaskClick(e) {
    const { lead } = this.props;

    const taskData = { id: lead.task_id, status: 'done' };

    TaskActions.updateTask(taskData);

    GlobalContainer.notify('Task Marked as Done');
  }

  onChange = () => {
    const { table } = this.props;
    if (table.scope === 'documents') {
      this.setState({
        documents: DocumentStore.getState().documents,
      });
    }
  }

  onLeadClick(e, id = null) {
    e.preventDefault();

    const {
      table, lead, query, clickable, totalCount, rowIdx,
    } = this.props;
    const { helpers } = this.context;

    let leadID;
    if (id) {
      leadID = id;
    } else {
      leadID = table.scope === 'today_or_past_tasks'
        || table.scope === 'tasks_due_later'
        || table.scope === 'tasks_overdue'
        || table.scope === 'all_tasks'
        ? lead.lead_id
        : lead.id;
    }

    if (clickable === false) return;

    helpers.openLeadDrawer({
      loadLeadID: leadID,
      table,
      query,
      totalCount,
      rowIdx,
    });
  }

  onBulkSelect(e) {
    const selectedRow = e.target.checked;
    const {
      table, lead, document, trash_document,
    } = this.props;

    this.setState({ selectedRow });
    if (selectedRow) {
      if (table.scope === 'documents') {
        BulkSelectActions.selectDocumentItem(table, document);
      } else if (table.scope === 'trash_documents') {
        BulkSelectActions.selectTrashDocumentItem(table, trash_document);
      } else {
        BulkSelectActions.selectLeadItem(table, lead);
      }
    } else if (table.scope === 'documents') {
      BulkSelectActions.deselectDocumentItem(table, document);
    } else if (table.scope === 'trash_documents') {
      BulkSelectActions.deselectTrashDocumentItem(table, trash_document);
    } else {
      BulkSelectActions.deselectLeadItem(table, lead);
    }
  }

  onEditTaskClick(e) {
    e.preventDefault();

    const { lead } = this.props;

    ReactDOM.render(
      <TaskModal
        taskID={lead.task_id}
        dialogClass="modal-dialog modal-lg"
        loadLead={false}
      />,
      document.getElementById('primary-modal'),
    );
  }

  onDeleteTaskClick(e) {
    e.preventDefault();

    const { lead } = this.props;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this task?',
      callback: (ok) => {
        if (ok) {
          TaskActions.deleteTask(lead.task);
        }
      },
    });
  }

  setShowLimitAlert = (showLimitAlert) => {
    this.setState({ showLimitAlert });
  };

  removeDataFromArray(data, id) {
    const removeIndex = _lodash.map(data, 'id').indexOf(id);
    data.splice(removeIndex, 1);
    return data;
  }

  renderDocumentActions(document) {
    const isAdminOrStaff = Rails.helpers.currentUser.role.name === 'admin' || Rails.helpers.currentUser.role.name === 'staff';

    return (
      <td className="text-right" style={{ padding: '0' }}>
        <div style={{ display: 'flex', gap: '2px', justifyContent: 'flex-end' }}>
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={(e) => this.handleDownloadDocument(e, document)}
          >
            <Tooltipable text="Download">
              <FontAwesomeIcon icon={['far', 'download']} size="sm" />
            </Tooltipable>
          </button>

          {isAdminOrStaff && (
            <>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={(e) => this.handleEditDocumentClick(e, document)}
              >
                <Tooltipable text="edit Document">
                  <FontAwesomeIcon icon={['far', 'edit']} size="sm" />
                </Tooltipable>
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => this.handleChangeInternalClick(document)}
              >
                <Tooltipable text={document.internal ? 'Shared with admin/staff users only' : 'Shared with the referring agent/affiliate user'}>
                  <FontAwesomeIcon
                    icon={document.internal ? 'far fa-eye-slash' : 'far fa-eye'}
                    size="sm"
                  />
                </Tooltipable>
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={(e) => this.handleDocumentDelete(e, document)}
              >
                <Tooltipable text="Delete Document">
                  <FontAwesomeIcon icon={['far', 'trash-alt']} size="sm" />
                </Tooltipable>
              </button>
            </>
          )}
        </div>
      </td>
    );
  }

  renderRelatedContacts() {
    const { document } = this.props;

    if (!document.related_contacts.contact_details || document.related_contacts.contact_details.length === 0) {
      return <span>No contacts</span>;
    }

    const contactsToShow = document.related_contacts.contact_details.slice(0, 3);
    const showMore = document.related_contacts.contact_details.length > 3;

    return (
      <div style={{ display: 'flex', gap: '1px', alignItems: 'center' }}>
        {contactsToShow.map((contact) => (
          <Tooltipable key={contact.id} text={contact.name}>
            <div key={contact.id} onClick={(e) => this.onLeadClick(e, contact.id)}>
              {LeadHelpers.renderLeadAvatar(contact, { className: 'mr5' })}
            </div>
          </Tooltipable>
        ))}
        {showMore && '...'}
      </div>
    );
  }

  renderDocumentRows() {
    const { document } = this.props;
    const { selectedRow } = this.state;
    const documentUrl = `/documents/${document.id}`;
    return (
      <>
        <td width="3%" className="text-center">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`document-${document.id}`}
              checked={selectedRow}
              onChange={this.onBulkSelect}
            />
            <label className="custom-control-label" htmlFor={`document-${document.id}`} />
          </div>
        </td>

        <td width="20%" className="clickable text-grey-dark font-weight-bold">
          <a href={documentUrl} target="_blank" rel="noopener noreferrer" className="text-info">
            {document.title}
            <FontAwesomeIcon icon="external-link-alt" className="ml-2" />
          </a>
        </td>

        <td width="15%" className="clickable">
          {document.status ? document.status.name : 'No Status'}
        </td>

        <td width="15%" className="clickable">
          {formatedDate(document.uploaded_at)}
        </td>

        <td width="10%" className="clickable">
          {formatedFileSize(document.size)}
        </td>

        <td width="10%" className="clickable">
          {document.category ? document.category.name : 'No Category'}
        </td>
        <td width="10%" className="clickable d-none d-md-table-cell text-center">
          {document.owner && document.owner.avatar_url ? (
            <UserAvatar
              tooltip
              user={{ ...document.owner, avatar: { small: document.owner.avatar_url } }}
            />
          ) : (
            <span>No Owner</span>
          )}
        </td>
        <td width="20%" className="clickable">
          {this.renderRelatedContacts()}
        </td>
        <td width="20%" className="clickable">
          {this.renderDocumentActions(document)}
        </td>
      </>
    );
  }

  renderTrashRows() {
    const { trash_document } = this.props;
    const { selectedRow } = this.state;
    return (
      <>
        <td width="5%" className="text-center">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`trash-document-${trash_document.id}`}
              checked={selectedRow}
              onChange={this.onBulkSelect}
            />
            <label className="custom-control-label" htmlFor={`trash-document-${trash_document.id}`} />
          </div>
        </td>

        <td width="40%" className="clickable text-grey-dark font-weight-bold">
          {trash_document.title}
        </td>

        <td width="20%" className="clickable d-none d-md-table-cell text-center">
          {trash_document.trashed_by && trash_document.trashed_by.avatar_url ? (
            <UserAvatar
              tooltip
              user={{ ...trash_document.trashed_by, avatar: { small: trash_document.trashed_by.avatar_url } }}
            />
          ) : (
            <span>Unknown</span>
          )}
        </td>

        <td width="20%" className="clickable">
          {new Date(trash_document.trashed_at).toLocaleDateString()}
        </td>
        <td width="20%" className="clickable">
          {this.renderTrashActions(trash_document)}
        </td>
      </>
    );
  }

  renderTrashActions(trash_document) {
    const { showLimitAlert } = this.state;

    return (
      <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
        <DocumentLimitAlertChecker
          showLimitAlert={showLimitAlert}
          setShowLimitAlert={this.setShowLimitAlert}
          placement="left"
        >
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={(e) => this.handleRestoreDocument(e, trash_document)}
          >
            <FontAwesomeIcon icon={['fa-light', 'trash-undo']} size="sm" />
          </button>
        </DocumentLimitAlertChecker>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={(e) => this.handlePermanentDeleteDocument(e, trash_document)}
        >
          <FontAwesomeIcon icon={['far', 'fa-trash-alt']} size="sm" />
        </button>
      </div>
    );
  }

  renderLeadRows() {
    const { lead, timestampField, table } = this.props;
    const displayCustomStatuses = checkFeatureFlag(process.env.DISPLAY_CUSTOM_STATUSES_FLAG);

    return (
      <>
        {GlobalContainer.product() !== 'retention' && (
          <td
            width="15%"
            className="clickable d-none d-sm-table-cell"
            onClick={this.onLeadClick}
          >
            <small className="text-uppercase">
              {displayCustomStatuses
                ? lead.status
                : LeadHelpers.findStatusLabel(lead.status_id, ALL_STATUSES)}
            </small>
          </td>
        )}

        <td
          width="25%"
          className="clickable text-grey-dark font-weight-bold"
          onClick={this.onLeadClick}
        >
          {LeadHelpers.renderLeadAvatar(lead, { className: 'mr5' })}
          {LeadHelpers.renderLeadName(lead)}
        </td>

        <td
          width="15%"
          className="clickable d-none d-md-table-cell"
          onClick={this.onLeadClick}
        >
          {LeadHelpers.renderProductionValue(lead.production_ytd, {
            className: 'mr5',
          })}
          {LeadHelpers.renderProductionBadge(lead)}
        </td>

        {GlobalContainer.product() === 'retention' && (
          <td
            width="15%"
            className="clickable d-none d-md-table-cell"
            onClick={this.onLeadClick}
          >
            {lead.referrals_count}
          </td>
        )}

        {GlobalContainer.product() === 'retention' && (
          <td
            width="10%"
            className="clickable d-none d-md-table-cell"
            onClick={this.onLeadClick}
          >
            {months[lead.anniversary_month - 1]}
          </td>
        )}

        {GlobalContainer.product() === 'retention' && (
          <td
            width="10%"
            className="clickable d-none d-md-table-cell"
            onClick={this.onLeadClick}
          >
            {lead.birthday && Moment(lead.birthday).format('MMMM Do')}
          </td>
        )}

        {GlobalContainer.product() !== 'retention' && (
          <td
            width="15%"
            className="clickable d-none d-md-table-cell"
            onClick={this.onLeadClick}
          >
            {lead.currently_at && lead.currently_at.truncate(30)}
          </td>
        )}

        {timestampField && (
          <td
            width="10%"
            className="clickable d-none d-lg-table-cell"
            onClick={this.onLeadClick}
          >
            {GlobalContainer.product() === 'retention'
              ? LeadHelpers.renderLeadDateField(
                lead,
                timestampField,
                'fromNow',
              )
              : LeadHelpers.renderLeadDateField(lead, timestampField)}
          </td>
        )}

        {GlobalContainer.product() === 'retention' && (
          <td
            width="10%"
            className="clickable d-none d-sm-table-cell"
            onClick={this.onLeadClick}
          >
            {lead.last_direct_contact
              && Moment(lead.last_direct_contact).fromNow()}
          </td>
        )}

        {GlobalContainer.product() === 'recruiting'
          && table.scope === 'search_results' && (
            <td
              width="15%"
              className="clickable d-none d-md-table-cell"
              onClick={this.onLeadClick}
            >
              {lead.next_due_date
                && Moment(lead.next_due_date.due_date_at).format(
                  'MMMM Do',
                )}
            </td>
        )}

        {Rails.helpers.currentUser.staff_type === 'external' && (
          <td className="clickable d-none d-lg-table-cell">
            {LeadHelpers.renderLeadDateField(
              lead,
              'my_last_activity_at',
              'fromNow',
            )}
          </td>
        )}

        {Rails.abilities.manageLead && (
          <td
            className="clickable d-none d-md-table-cell text-center"
            onClick={this.onLeadClick}
          >
            <UserAvatar tooltip user={lead.owner} />
          </td>
        )}
      </>
    );
  }

  renderTaskRows() {
    const { lead, table } = this.props;
    const displayCustomStatuses = checkFeatureFlag(process.env.DISPLAY_CUSTOM_STATUSES_FLAG);

    return (
      <>
        {table.leadTasks
          && GlobalContainer.product() !== 'retention' && (
            <td
              width="15%"
              className="clickable"
              onClick={this.onLeadClick}
            >
              <small className="text-uppercase">
                {displayCustomStatuses
                  ? lead.status
                  : LeadHelpers.findStatusLabel(lead.status_id, ALL_STATUSES)}
              </small>
            </td>
        )}

        <td
          width="15%"
          className="clickable text-grey-dark font-weight-bold"
          onClick={this.onLeadClick}
        >
          {LeadHelpers.renderLeadAvatar(lead, { className: 'mr5' })}
          {LeadHelpers.renderLeadName(lead)}
        </td>

        <td
          width="15%"
          className="clickable"
          onClick={this.onLeadClick}
        >
          {LeadHelpers.renderProductionValue(lead.production_ytd, {
            className: 'mr5',
          })}
          {LeadHelpers.renderProductionBadge(lead)}
        </td>

        {table.leadTasks
          && GlobalContainer.product() !== 'retention' && (
            <td
              width="15%"
              className="clickable"
              onClick={this.onLeadClick}
            >
              {lead.currently_at && lead.currently_at.truncate(30)}
            </td>
        )}

        <td
          width="15%"
          className="clickable"
          onClick={this.onLeadClick}
        >
          {lead.task_name}
        </td>

        {(!table.leadTasks
          || GlobalContainer.product() === 'retention') && (
          <td
            width="5%"
            className="clickable"
            onClick={this.onLeadClick}
          >
            <small className="text-uppercase">
              {LeadHelpers.renderTaskStatus(lead.task_status)}
            </small>
          </td>
        )}

        {!table.leadTasks && (
          <td
            width="5%"
            className="clickable"
            onClick={this.onLeadClick}
          >
            <small className="text-uppercase">
              {LeadHelpers.renderTaskPriority(lead.task_priority)}
            </small>
          </td>
        )}

        <td
          width="10%"
          className="clickable"
          onClick={this.onLeadClick}
        >
          <small className="text-uppercase">
            {LeadHelpers.renderTaskCategory(lead.task_category)}
          </small>
        </td>

        <td
          width="5%"
          className="clickable"
          onClick={this.onLeadClick}
        >
          {LeadHelpers.renderLeadDateField(
            lead,
            'task_due_date_at',
            'l',
          )}
        </td>

        <td
          width="5%"
          className="clickable d-none d-sm-table-cell text-center"
          onClick={this.onLeadClick}
        >
          <UserAvatar tooltip user={lead.task_owner} />
        </td>

        <td width="10%" className="clickable text-center">
          {(lead.task_status === 'to_do'
            || lead.task_status === 'in_progress') && (
            <Tooltipable text="Mark Task as Done">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr5"
                onClick={this.handleMarkAsDoneTaskClick}
              >
                <FontAwesomeIcon icon={['far', 'fa-check-square']} />
              </button>
            </Tooltipable>
          )}

          <Tooltipable text="Edit Task">
            <button
              type="button"
              className="btn btn-secondary btn-sm mr5"
              onClick={this.onEditTaskClick}
            >
              <FontAwesomeIcon icon={['far', 'fa-edit']} />
            </button>
          </Tooltipable>

          <Tooltipable text="Delete Task">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={this.onDeleteTaskClick}
            >
              <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
            </button>
          </Tooltipable>
        </td>
      </>
    );
  }

  renderBulkSelector() {
    if (Rails.abilities.manageLead === false) return null;

    const id = uuidv4();
    const { selectedRow } = this.state;

    return (
      <td width="1%" className="d-md-table-cell pt20 text-center">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={id}
            checked={selectedRow}
            onChange={this.onBulkSelect}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="custom-control-label" htmlFor={id} />
        </div>
      </td>
    );
  }

  render() {
    const { selectedRow } = this.state;
    const { table } = this.props;
    const rowClass = classNames('table-item', {
      selected: selectedRow,
    });

    return (
      <tr className={rowClass}>
        {(table.scope === 'documents' && this.renderDocumentRows())
          || (table.scope === 'trash_documents' && this.renderTrashRows()) || (
            <>
              {this.renderBulkSelector()}
              {(table.scope === 'today_or_past_tasks'
                || table.scope === 'tasks_due_later'
                || table.scope === 'tasks_overdue'
                || table.scope === 'all_tasks')
                ? this.renderTaskRows()
                : this.renderLeadRows()}
            </>
        )}
      </tr>
    );
  }
}

DataTableItem.contextTypes = {
  helpers: PropTypes.shape({}),
};

DataTableItem.defaultProps = {
  table:                {},
  query:                {},
  clickable:            true,
  totalCount:           0,
  rowIdx:               null,
  selectedKeys:         [],
  onItemSelect:         () => false,
  timestampField:       'referred_at',
};

DataTableItem.propTypes = {
  selectAllItems:       PropTypes.bool.isRequired,
  table:                PropTypes.shape({}),
  query:                PropTypes.shape({}),
  clickable:            PropTypes.bool,
  totalCount:           PropTypes.number,
  rowIdx:               PropTypes.number,
  lead:                 PropTypes.shape({}).isRequired,
  selectedKeys:         PropTypes.arrayOf(PropTypes.string),
  onItemSelect:         PropTypes.func,
  timestampField:       PropTypes.string,
};

export default DataTableItem;

import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import { Input }           from 'reactstrap';
import pluralize           from 'pluralize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltipable        from '~/components/effects/tooltipable';
import APIRequest         from '~/lib/api_request';
import TaskCategorySelect from '~/components/forms/task_category_select';
import TaskPrioritySelect from '~/components/forms/task_priority_select';
import TaskStatusSelect   from '~/components/forms/task_status_select';
import TaskEditor         from '~/components/forms/HtmlEditors/TaskEditor';
import DateTimePicker     from '~/components/forms/DateTimePicker';
import { TeammatePicker } from '~/components/forms/team_member_picker';
import AppModal           from './app_modal';

class AddTaskModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting:      false,
      errors:          {},
      loadingContacts: false,
      task:            {
        name:        'Follow Up',
        category:    'follow_up',
        due_date_at: Moment().format('YYYY-MM-DD'),
        description: '',
        owner_id:    Rails.helpers.currentUser.id,
        priority:    'normal',
        status:      'to_do',
        lead_ids:    [],
      },
    };
  }

  componentDidMount() {
    const {
      table,
      records,
      searchData,
      bulkSelecting,
      selectedItems,
      unSelectedItems,
      blastTeam,
    } = this.props;

    let taskData;
    let selectedLeads = Object.values(selectedItems);
    const unselectedLeads = Object.values(unSelectedItems);

    if (bulkSelecting) {
      selectedLeads = 'all';
      taskData = {
        ...searchData,
        ids:            'all',
        unselected_ids: _lodash.map(unselectedLeads, 'id'),
      };
    } else if (blastTeam) {
      selectedLeads = 'blast';
      taskData = { ...searchData, ids: 'blast' };
    } else {
      taskData = {
        ...searchData,
        ids: _lodash.map(selectedLeads, 'id'),
      };
    }

    this.setState({ loadingContacts: true }, () => {
      APIRequest.post({
        resource: '/v1/lead_bulk_emails/recipients',
        data:     taskData,
      }).end((error, response) => {
        this.setState({ loadingContacts: false }, () => {
          if (!error) {
            const lead_ids = [..._lodash.map(response.body, 'id')];
            this.setTaskField('lead_ids', lead_ids);
          }
        });
      });
    });
  }

  setTaskField(name, val) {
    this.setState((prevState) => ({
      task: {
        ...prevState.task,
        [name]: val,
      },
    }));
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const $modal = $(this.appModal.modal);

    this.setState({ submitting: true }, () => {
      const { task } = this.state;
      const errors = this.validate();

      if (_lodash.size(errors) === 0) {
        APIRequest.post({
          resource: '/v1/tasks/bulk_create',
          data:     { task },
        }).end((error) => {
          this.setState({ submitting: false }, () => {
            if (!error) {
              const { countSelectedItems, reloadLeads } = this.props;
              const selectedItemsCount = countSelectedItems();
              GlobalContainer.notify(
                `Task will be add to ${selectedItemsCount} contacts`,
                'success',
              );

              $modal.modal('hide');

              reloadLeads();
            }
          });
        });
      } else {
        this.setState({ submitting: false, errors });
      }
    });
  };

  handleMarkAsDoneTaskClick = (e) => {
    e.preventDefault();

    const $modal = $(this.appModal.modal);

    this.setTaskField('status', 'done');
    this.setState({ submitting: true }, () => {
      const { task } = this.state;
      const errors = this.validate();

      if (_lodash.size(errors) === 0) {
        APIRequest.post({
          resource: '/v1/tasks/bulk_create',
          data:     { task },
        }).end((error) => {
          this.setState({ submitting: false }, () => {
            if (!error) {
              const { countSelectedItems, reloadLeads } = this.props;
              const selectedItemsCount = countSelectedItems();
              GlobalContainer.notify(
                `Task will be add to ${selectedItemsCount} contacts and marked as done`,
                'success',
              );

              $modal.modal('hide');

              reloadLeads();
            }
          });
        });
      } else {
        this.setState({ submitting: false, errors });
      }
    });
  };

  validate() {
    const { task } = this.state;
    const errors = {};

    if (!task.name) {
      errors.name = "Can't be empty";
    }

    if (!task.category) {
      errors.category = "Can't be empty";
    }

    if (!task.due_date_at) {
      errors.due_date_at = "Can't be empty";
    }

    if (!task.priority) {
      errors.priority = "Can't be empty";
    }

    if (!task.status) {
      errors.status = "Can't be empty";
    }

    if (_lodash.isEmpty(task.lead_ids)) {
      errors.lead_ids = "Can't be empty";
    }

    this.setState({ errors });

    return errors;
  }

  renderForm() {
    const { task, errors, loadingContacts } = this.state;

    if (loadingContacts) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse />
        </div>
      );
    }

    return (
      <>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="task_name">Task Name</label>
            <Input
              id="task_name"
              name="name"
              value={task.name}
              className={classNames(
                'd-block',
                errors.name ? 'has-error' : '',
              )}
              placeholder="Enter Name"
              onChange={(val) => this.setTaskField('name', val && val.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="inputCategory">Task Category</label>
            <TaskCategorySelect
              id="inputCategory"
              name="category"
              placeholder="Select Category"
              value={task.category}
              className={errors.category ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('category', opt && opt.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="task_due_date_at">Due Date</label>
            <DateTimePicker
              id="task_due_date_at"
              placeholder="Select Due Date"
              format="LL"
              pickerType="date"
              minDate={Moment().toDate()}
              value={Moment(task.due_date_at)?.format('LL')}
              onChange={(picker) => this.setTaskField(
                'due_date_at',
                picker && picker.toDate(),
              )}
            />
          </div>
        </div>

        <div className="form-group mb15">
          <label htmlFor="task-description" className="d-block">Description</label>
          <TaskEditor
            className={errors.description ? 'tiny-mce has-error' : ''}
            onChange={(html) => this.setTaskField('description', html)}
            placeholder="Enter Description"
          />
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label
              htmlFor="task_owner_id"
              className="form-control-label"
            >
              Owner
            </label>
            <TeammatePicker
              id="task_owner_id"
              name="owner_id"
              placeholder="Owner"
              value={task.owner_id}
              onChange={(opt) => this.setTaskField('owner_id', opt && opt.value)}
              clearable
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="task_priority">Priority</label>
            <TaskPrioritySelect
              id="task_priority"
              name="priority"
              placeholder="Select Priority"
              value={task.priority}
              className={errors.priority ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('priority', opt && opt.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="task_status">Status</label>
            <TaskStatusSelect
              id="task_status"
              name="status"
              placeholder="Select Status"
              value={task.status}
              className={errors.status ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('status', opt && opt.value)}
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    const { submitting } = this.state;
    const { countSelectedItems } = this.props;

    const saveButtonClass = classNames('btn btn-primary', {
      disabled: submitting,
    });
    const spinnerImg = (
      <FontAwesomeIcon icon="far fa-spinner" pulse />
    );

    const selectedItemsCount = countSelectedItems();
    const selectedItems = pluralize('Task', selectedItemsCount);

    return (
      <AppModal
        ref={(appModal) => (this.appModal = appModal)}
        dialogClass="modal-dialog modal-lg"
      >
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              Add Task
              {' '}
              <small>
                (to
                {' '}
                {countSelectedItems()}
                {' '}
                contacts)
              </small>
              {' '}
              {submitting && spinnerImg}
            </h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">{this.renderForm()}</div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={saveButtonClass}
              disabled={submitting}
            >
              Add Task
            </button>
            <Tooltipable text={`Mark ${selectedItems} as Done`}>
              <button
                type="button"
                className={saveButtonClass}
                disabled={submitting}
                onClick={this.handleMarkAsDoneTaskClick}
              >
                <FontAwesomeIcon icon={['far', 'fa-check-square']} />
              </button>
            </Tooltipable>
          </div>
        </form>
      </AppModal>
    );
  }
}

AddTaskModal.defaultProps = {
  countSelectedItems: () => {},
  table:              {},
  records:            {},
  selectedItems:      {},
  bulkSelecting:      false,
  searchData:         {},
  unSelectedItems:    {},
};

AddTaskModal.propTypes = {
  countSelectedItems: PropTypes.func,
  table:              PropTypes.shape({}),
  records:            PropTypes.shape({}),
  selectedItems:      PropTypes.shape({}),
  bulkSelecting:      PropTypes.bool,
  searchData:         PropTypes.shape({}),
  unSelectedItems:    PropTypes.shape({}),
};

export default AddTaskModal;

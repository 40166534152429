import APIRequest from '~/lib/api_request';

export const labelApi = {
  fetchLabels() {
    return new Promise((resolve, reject) => {
      const request = APIRequest.get({
        resource: '/v1/labels',
      });

      request.end((error, response) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(response.body);
      });
    });
  },

  createLabel(label) {
    return new Promise((resolve, reject) => {
      const request = APIRequest.post({
        resource: '/v1/labels',
        data:     { label },
      });

      request.end((error, response) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(response.body);
      });
    });
  },

  updateLabel(label) {
    return new Promise((resolve, reject) => {
      const request = APIRequest.put({
        resource: `/v1/labels/${label.id}`,
        data:     { label },
      });

      request.end((error, response) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(response.body);
      });
    });
  },

  deleteLabel(labelId) {
    return new Promise((resolve, reject) => {
      const request = APIRequest.delete({
        resource: `/v1/labels/${labelId}`,
      });

      request.end((error, response) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(response.body);
      });
    });
  },
};

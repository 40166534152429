import autoBind         from 'react-autobind';
import { v4 as uuidv4 } from 'uuid';
import React            from 'react';
import ReactDOM         from 'react-dom';
import { PropTypes }    from 'prop-types';
import {
  InputGroup, InputGroupAddon, Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppModal from '~/components/modals/app_modal';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import SendEmailModal from '~/components/modals/send_email_modal';
import SendSMSModal from '~/components/modals/send_sms_modal';
import RecruitingSearchFilters from '~/components/pages/agent_dashboard/search_form/recruiting_search_filters';
import RetentionSearchFilters from '~/components/pages/agent_dashboard/search_form/retention_search_filters';
import ArchivedSearchFilters from '~/components/pages/agent_dashboard/search_form/archived_search_filters';

class SearchForm extends React.Component {
  static defaultSearchValues() {
    return {
      query:                                      '',
      archived:                                   false,
      owner:                                      [],
      referrer:                                   [],
      production:                                 [],
      status:                                     [],
      source:                                     [],
      currently_at:                               [],
      city:                                       [],
      label:                                      [],
      rating:                                     [],
      bucket:                                     [],
      role:                                       [],
      campaign:                                   [],
      engagement:                                 '',
      engagement_time_period:                     '30',
      joined_at_start:                            '',
      joined_at_end:                              '',
      referred_at_start:                          '',
      referred_at_end:                            '',
      due_date_at_start:                          '',
      due_date_at_end:                            '',
      last_activity_at_start:                     '',
      last_activity_at_end:                       '',
      last_appointment_at_start:                  '',
      last_appointment_at_end:                    '',
      anniversary_month:                          '',
      license_exp_date_start:                     '',
      license_exp_date_end:                       '',
      production_select_value:                    '',
      production_start_value:                     '',
      production_end_value:                       '',
      production_insights_select_value:           '',
      production_insights_start_value:            '',
      production_insights_end_value:              '',
      production_location_start_value:            '',
      production_location_end_value:              '',
      production_insights_listing_eff_grade:      '',
      production_insights_current_switch_risk:    [],
      production_location_select_value:           '',
      production_location_closest_office:         [],
      production_location_closest_office_address: [],
      center_of_production_zips:                  [],
      lead_office_zip:                            [],
    };
  }

  static buildSearchData(params = {}) {
    const urlParams = _lodash.isEmpty(params)
      ? GlobalContainer.urlParams()
      : params;
    const searchObj = urlParams.s;

    if (!searchObj) return SearchForm.defaultSearchValues();

    const { engagement } = searchObj;
    const engagementTimePeriod = searchObj.engagement
      ? searchObj.engagement_time_period
      : '30';

    return {
      query:                            searchObj.query,
      archived:                         searchObj.archived === 'true',
      owner:                            searchObj.owner,
      referrer:                         searchObj.referrer,
      production:                       searchObj.production,
      status:                           searchObj.status,
      source:                           searchObj.source,
      currently_at:                     searchObj.currently_at,
      city:                             searchObj.city,
      label:                            searchObj.label,
      rating:                           searchObj.rating,
      bucket:                           searchObj.bucket,
      role:                             searchObj.role,
      campaign:                         searchObj.campaign,
      engagement,
      engagement_time_period:           engagementTimePeriod,
      birthday_start:                   searchObj.birthday_start,
      birthday_end:                     searchObj.birthday_end,
      joined_at_start:                  searchObj.joined_at_start,
      joined_at_end:                    searchObj.joined_at_end,
      referred_at_start:                searchObj.referred_at_start,
      referred_at_end:                  searchObj.referred_at_end,
      due_date_at_start:                searchObj.due_date_at_start,
      due_date_at_end:                  searchObj.due_date_at_end,
      last_activity_at_start:           searchObj.last_activity_at_start,
      last_activity_at_end:             searchObj.last_activity_at_end,
      last_appointment_at_start:        searchObj.last_appointment_at_start,
      last_appointment_at_end:          searchObj.last_appointment_at_end,
      anniversary_month:                searchObj.anniversary_month,
      license_exp_date_start:           searchObj.license_exp_date_start,
      license_exp_date_end:             searchObj.license_exp_date_end,
      production_select_value:          searchObj.production_select_value,
      production_insights_select_value:
        searchObj.production_insights_select_value,

      production_location_select_value:
        searchObj.production_location_select_value,
      production_start_date:           searchObj.production_start_date,
      production_end_date:             searchObj.production_end_date,
      production_start_value:          searchObj.production_start_value,
      production_end_value:            searchObj.production_end_value,
      production_insights_start_value:
        searchObj.production_insights_start_value,
      production_insights_end_value:
        searchObj.production_insights_end_value,

      production_location_start_value:
        searchObj.production_location_start_value,
      production_location_end_value:
        searchObj.production_location_end_value,
      production_closest_office:                  searchObj.production_closest_office,
      production_location_closest_office_address:
        searchObj.production_location_closest_office_address,
      center_of_production_zips:     searchObj.center_of_production_zips,
      production_dates_select_value:
        searchObj.production_dates_select_value,
      production_insights_current_switch_risk:
        searchObj.production_insights_current_switch_risk,
      production_insights_listing_eff_grade:
        searchObj.production_insights_listing_eff_grade,
      lead_office_zip: searchObj.lead_office_zip,
    };
  }

  doAutoComplete = _lodash.debounce((searchData, query) => {
    const { handleSearch } = this.props;

    handleSearch({
      ...searchData,
      query: encodeURIComponent(query),
    });
  }, 850);

  constructor(props) {
    super(props);

    const params = GlobalContainer.urlParams();

    this.state = {
      showFilters: !!location.pathname.match('/search$'), // on/off filters default by search page
      searchData:  SearchForm.defaultSearchValues(),
      filtersKey:  uuidv4(),
    };

    if (params.s) {
      this.state.searchData = SearchForm.buildSearchData();
    }

    autoBind(this);
  }

  componentDidMount() {
    // autofocus to the end of search query input text
    if (this.queryInput) {
      const len = this.queryInput.value.length;
      this.queryInput.focus();
      this.queryInput.setSelectionRange(len, len);
    }

    const { history } = this.context;
    const { searchData } = this.state;

    // listen to URL hash changes
    this.unlisten = history
      .listen((location, action) => {
        const urlParams = GlobalContainer.urlParams();
        if (_lodash.isEmpty(urlParams) || !urlParams.s) return;

        const newSearchData = {
          ...searchData,
          ...SearchForm.buildSearchData(),
        };
        this.setState({ searchData: newSearchData });
      })
      .bind(this);
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  handleSubmit(e) {
    e.preventDefault();
    // this.context.handleSearch(this.state.searchData);
    const { searchData } = this.state;
    const { handleSearch } = this.props;

    handleSearch({
      ...searchData,
      query: encodeURIComponent(searchData.query),
    });
  }

  handleQueryChange(e) {
    const query = e.target.value || '';
    const searchData = Object.assign(this.state.searchData, {
      query,
    });

    this.setState({ searchData });
    this.doAutoComplete(searchData, query);
  }

  handleSearchDataChange(searchData) {
    const data = this.state.searchData;

    const newSearchData = {
      ...data,
      ...searchData,
      query: encodeURIComponent(data.query),
    };

    this.setState({ searchData: newSearchData });

    clearTimeout(this.delayTimer);

    setTimeout(() => {
      this.props.handleSearch(newSearchData);
    }, 0);
  }

  handleSingleSelectChange(fieldName, fieldValue) {
    this.handleSearchDataChange({ [fieldName]: fieldValue });
  }

  handleMultiSelectChange(fieldName, selectedOptions) {
    this.handleSearchDataChange({
      [fieldName]: _lodash.map(selectedOptions, (opt) => opt.value),
    });
  }

  handleDateSelectChange(fieldName, fieldValue) {
    const updatedData = {
      [fieldName]: Moment.isMoment(fieldValue) ? fieldValue.format('YYYY-MM-DD') : null,
    };
    setTimeout(() => {
      this.handleSearchDataChange(updatedData);
    }, 0);
  }

  handleCheckBoxClick(fieldName, e) {
    this.handleSearchDataChange({ [fieldName]: e.target.checked });
  }

  onBlastEmail(e) {
    e.preventDefault();

    const isBlastTeamRestricted = checkFeatureFlag(process.env.RESTRICT_BLAST_TEAM_FLAG);

    if (!isBlastTeamRestricted) {
      ReactDOM.render(
        <SendEmailModal blastTeam />, document.getElementById('primary-modal'),
      );
    } else {
      ReactDOM.render(
        this.renderWarningModal(), document.getElementById('primary-modal'),
      );
    }
  }

  onBlastSMS(e) {
    e.preventDefault();

    const isBlastTeamRestricted = checkFeatureFlag(process.env.RESTRICT_BLAST_TEAM_FLAG);

    if (!isBlastTeamRestricted) {
      ReactDOM.render(
        <SendSMSModal blastTeam />, document.getElementById('primary-modal'),
      );
    } else {
      ReactDOM.render(
        this.renderWarningModal(), document.getElementById('primary-modal'),
      );
    }
  }

  showFilters() {
    this.setState({
      showFilters: true,
    });
  }

  hideFilters() {
    this.setState({
      showFilters: false,
    });
  }

  registerQueryInput(input) {
    this.queryInput = input;
  }

  clearSearch() {
    const { handleSearch } = this.props;

    this.setState({
      searchData: SearchForm.defaultSearchValues(),
      filtersKey: uuidv4(), // regenerate a key so the old component gets trashed
    });

    // this.context.handleSearch(SearchForm.defaultSearchValues());
    handleSearch(SearchForm.defaultSearchValues());
  }

  renderFilters() {
    const { searchData, showFilters, filtersKey } = this.state;

    if (showFilters === false) {
      return (
        <div className="form-group mb0">
          <a href="#show-filters" onClick={this.showFilters}>
            Show advanced options
          </a>
        </div>
      );
    }

    const props = {
      key:                      filtersKey,
      searchData,
      hideFilters:              this.hideFilters,
      clearSearch:              this.clearSearch,
      handleSingleSelectChange: this.handleSingleSelectChange,
      handleMultiSelectChange:  this.handleMultiSelectChange,
      handleDateSelectChange:   this.handleDateSelectChange,
      handleCheckBoxClick:      this.handleCheckBoxClick,
    };

    if (GlobalContainer.product() === 'retention') {
      return <RetentionSearchFilters {...props} />;
    }

    if (GlobalContainer.product() === 'archived') {
      return <ArchivedSearchFilters {...props} />;
    }

    return <RecruitingSearchFilters {...props} />;
  }

  renderWarningModal() {
    return (
      <AppModal
        ref={(appModal) => (this.appModal = appModal)}
        dialogClass="modal-dialog"
      >
        <div className="modal-header">
          <h5 className="modal-title">Limited Access</h5>

          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body p0">
          <div className="alert alert-warning" role="alert">
            Oops! Limited Access.
            <br />
            The &quot;Blast Team&quot; option is available only for users with the admin role.
            Please check with one of your admins to get your role updated if needed.
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </AppModal>
    );
  }

  render() {
    const { searchData } = this.state;

    return (
      <div className="search-form card p20 mb15">
        <form
          action="/recruiter/search"
          method="GET"
          onSubmit={this.handleSubmit}
        >
          <div className="form-group mb5">
            <div className="row">
              <div className="col-md-12">
                <InputGroup className="mr5">
                  <input
                    ref={this.registerQueryInput}
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchData.query}
                    onChange={this.handleQueryChange}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="secondary">
                      <FontAwesomeIcon
                        icon={['far', 'fa-search']}
                        className="mr5"
                      />
                      {' '}
                      Search
                    </Button>
                  </InputGroupAddon>

                  {GlobalContainer.product() === 'retention' && (
                    <div className="dropdown ml10">
                      <button
                        type="button"
                        className="btn btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        href="#blast-team-dropdown"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'fa-bullhorn']}
                          className="mr5"
                        />
                        {' '}
                        Blast Team
                      </button>

                      <div className="dropdown-menu">
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={this.onBlastSMS}
                        >
                          Text Team
                        </button>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={this.onBlastEmail}
                        >
                          Email Team
                        </button>
                      </div>
                    </div>
                  )}
                </InputGroup>
              </div>
            </div>
          </div>

          {this.renderFilters()}
        </form>
      </div>
    );
  }
}

SearchForm.contextTypes = {
  history:      PropTypes.shape({}),
  location:     PropTypes.shape({}),
  match:        PropTypes.shape({}),
  handleSearch: PropTypes.func,
};

SearchForm.defaultProps = {
  handleSearch: () => false,
};

SearchForm.propTypes = {
  handleSearch: PropTypes.func,
};

export default SearchForm;

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { labelApi } from '~/pages/Labels/api/labelApi';
import APIRequest from '~/lib/api_request';
import ErrorMessage from '~/components/forms/ErrorMessage';
import AutosuggestInput from '~/components/forms/autosuggest_input';

const LabelingForm = ({ leadID, onLabelCreated }) => {
  const [labels, setLabels] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [label, setLabel] = useState({
    id:   null,
    name: '',
  });

  useEffect(() => {
    const loadLabels = async () => {
      try {
        setIsLoading(true);
        const fetchedLabels = await labelApi.fetchLabels();
        setLabels(fetchedLabels);
      } catch (error) {
        console.error('Error loading labels:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadLabels();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setErrors({});

    try {
      const response = await new Promise((resolve, reject) => {
        const request = APIRequest.post({
          resource: `/v1/leads/${leadID}/labels`,
          data:     { label },
        });

        request.end((error, apiResponse) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(apiResponse.body);
        });
      });

      setLabel({ id: null, name: '' });
      onLabelCreated?.(response);
      setLabels((prevLabels) => [response, ...prevLabels]);
    } catch (error) {
      console.error('Error creating label:', error);
      setErrors(error.response?.body?.errors || { name: 'Failed to create label' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNameChange = (value) => {
    setLabel((prevLabel) => ({
      ...prevLabel,
      name: value,
    }));

    if (errors) {
      setErrors({});
    }
  };

  const error = errors.name || errors.label_id;
  const isFormDisabled = isLoading || isSubmitting;

  return (
    <div>
      <form method="POST" action="/labels" className="form-inline" onSubmit={handleFormSubmit}>
        <div className="form-group ml-3">
          <AutosuggestInput
            suggestions={labels}
            id="label_name"
            name="label[name]"
            placeholder={isLoading ? 'Loading labels...' : 'Type a Label Name'}
            onChange={handleNameChange}
            styles={`${classNames('form-control', { 'has-error': error })}`}
            value={label.name}
            disabled={isFormDisabled}
          />
        </div>

        <div className="form-group mx-3">
          {isSubmitting ? (
            <button type="submit" className="btn btn-primary disabled" disabled>
              <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
              Saving ...
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isFormDisabled}
            >
              Add New Label
            </button>
          )}
        </div>
      </form>

      <div className="ml-3">{error && <ErrorMessage message={error} />}</div>
    </div>
  );
};

LabelingForm.propTypes = {
  leadID:         PropTypes.number.isRequired,
  onLabelCreated: PropTypes.func,
};

LabelingForm.defaultProps = {
  onLabelCreated: () => {},
};

export default LabelingForm;

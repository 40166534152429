import React from 'react'
import PropTypes from 'prop-types'

const GoogleMapLink = (props) => {
  const { q, children, disabled } = props
  const encodedQuery = encodeURIComponent(q)

  return (
    <a
      href={disabled ? '#' : `https://www.google.com/maps/place/${encodedQuery}`}
      target="_blank"
      rel="noopener noreferrer"
      className={disabled ? 'no-pointer-events' : ''}
    >
      {children}
    </a>
  )
}

GoogleMapLink.propTypes = {
  q: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

GoogleMapLink.defaultProps = {
  disabled: false,
};

export default GoogleMapLink;

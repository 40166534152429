import autoBind   from 'react-autobind';
import React      from 'react';

import LeadDrawerActions from '~/actions/lead_drawer_actions';
import LeadHelpers       from '~/helpers/lead_helpers';
import ActivityEditor    from '~/components/forms/HtmlEditors/ActivityEditor';
import TimeSelect        from '~/components/forms/dropdowns/TimeSelect';
import FormFooter        from './common/form_footer';
import DateTimePicker    from '~/components/forms/DateTimePicker';
import InsertDocumentButton from '~/components/insert_document_button';

const CurrentDate = Moment().startOf('day').toDate();

class LogAppointmentForm extends React.Component {
  constructor(props) {
    super(props);

    const { lead } = this.props;

    this.state = {
      occurred_at_date: Moment().local(),
      occurred_at_time: null,
      status:   lead.status,
      internal: GlobalContainer.product() === 'retention',
      rating:   props.lead.rating,
      errors:   {},
    };

    autoBind(this);
  }

  onChangeDate(m) {
    this.setState({ occurred_at_date: m });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const { body, occurred_at_date } = this.state;
    const { lead } = this.props;

    this.setState({ sending: true });

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      const date = Moment(occurred_at_date)
        .set('hour', 0)
        .set('minutes', 0)
        .set('second', 0);

      const footer = this.footer.serialize();
      const {
        status,
        rating,
        task,
        internal,
        notify,
      } = footer;

      const data = {
        status,
        rating,
        task,
        event:    'appointment',
        activity: {
          body,
          occurred_at: date,
          internal,
        },
      };

      LeadDrawerActions.createLeadActivity(lead, data);
    } else {
      this.setState({ sending: false, errors });
    }
  };

  validate() {
    const { occurred_at_date } = this.state;
    const errors = {};

    if (!occurred_at_date) {
      errors.occurred_at_date = "Can't be empty";
    }

    return this.footer.validate(errors);
  }

  render() {
    const { lead } = this.props;
    const {
      errors,
      occurred_at_date,
      status,
      internal,
      rating,
      occurred_at_time,
      linksToInsert,
    } = this.state;
    const userTimezone = LeadHelpers.tzAbbr(Moment.tz.guess());

    return (
      <form onSubmit={this.onFormSubmit}>
        <label htmlFor="appt_datetime" className="d-block">Appt Date and Time</label>
        <div className="row mb10">
          <div className="col-sm-6 col-xl-4 pl0 pr0-xs-down mb5">
            <DateTimePicker
              id="appt_datetime"
              placeholder="Select Date"
              format="LL"
              pickerType="date"
              maxDate={CurrentDate}
              isInvalid={errors.occurred_at_date}
              value={occurred_at_date.format('LL')}
              onChange={this.onChangeDate}
            />
          </div>

          <div className="col-sm-6 col-xl-4 pr0 pl0-xs-down mb5">
            <TimeSelect
              name="status"
              placeholder={`Select Time (${userTimezone})`}
              value={occurred_at_time}
              startHour="6"
              endHour="20"
              className={errors.occurred_at_time ? 'has-error' : ''}
              onChange={(opt) => {
                this.setState({ occurred_at_time: opt ? opt.value : '' });
              }}
            />
          </div>
        </div>

        <div className="form-group mb15">
          <label htmlFor="appt_description" className="d-block">Note</label>
          <ActivityEditor
            className={errors.body ? 'tiny-mce has-error' : ''}
            onChange={(html) => {
              this.setState({ body: html });
            }}
            placeholder="Enter Text"
            activityType="log_appointment"
            spellCheck
            linksToInsert={linksToInsert}
            clearLinksToInsert={() => {
              this.setState({ linksToInsert: [] });
            }}
          />
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <InsertDocumentButton
              setLinksToInsert={(linksToInsert) => this.setState({ linksToInsert })}
            />
          </div>
        </div>

        <div className="mt15 mb15">
          <FormFooter
            ref={(el) => (this.footer = el)}
            activityType="log_appointment"
            status={status}
            internal={internal}
            rating={rating}
            lead={lead}
            onSubmit={this.onFormSubmit}
            submitButtonText="Log Appointment"
            showRatingSelect
          />
        </div>
      </form>
    );
  }
}

export default LogAppointmentForm;

import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from 'react-bootstrap/Collapse';
import APIRequest from '~/lib/api_request';
import LabelingForm from './forms/labeling_form';
import LeadDrawerLabel from './details/lead_drawer_label';

const LeadDrawerLabels = ({ lead }) => {
  const canManageLead = Rails.abilities.manageLead;
  const isOpenLocalStorage = localStorage.isDetailsOpen;
  const [isOpen, setIsOpen] = useState(isOpenLocalStorage ? JSON.parse(isOpenLocalStorage) : false);
  const [labels, setLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchLabels = useCallback(async () => {
    if (!lead?.id) return;

    setIsLoading(true);

    try {
      const request = APIRequest.get({
        resource: `/v1/leads/${lead.id}/labels`,
      });

      const response = await new Promise((resolve, reject) => {
        request.end((error, res) => {
          if (error) reject(error);
          else resolve(res);
        });
      });

      setLabels(response.body);
    } catch (error) {
      console.error('Error loading labels:', error);
    } finally {
      setIsLoading(false);
    }
  }, [lead?.id]);

  useEffect(() => {
    const abortController = new AbortController();

    const loadData = async () => {
      await fetchLabels();
    };

    loadData();

    return () => {
      abortController.abort();
    };
  }, [fetchLabels]);

  const handleLabelCreated = useCallback((newLabel) => {
    setLabels((prevLabels) => [newLabel, ...prevLabels]);
  }, []);

  const handleLabelRemoved = useCallback((removedLabel) => {
    setLabels((prevLabels) => prevLabels.filter((label) => label.id !== removedLabel.id));
  }, []);

  const onCollapseClick = useCallback((e) => {
    e.preventDefault();
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    localStorage.isDetailsOpen = newIsOpen;
  }, [isOpen]);

  const renderLoading = () => (
    <div className="text-center card-block">
      <FontAwesomeIcon
        icon="far fa-spinner"
        pulse
      />
    </div>
  );

  const renderLeadLabels = () => {
    if (isLoading) return renderLoading();

    if (!labels || labels.length === 0) {
      return (
        <div className="text-grey-dark card-block">
          No labels
        </div>
      );
    }

    return labels.map((label) => (
      <LeadDrawerLabel
        key={`label-${label.id}`}
        leadID={lead.id}
        label={label}
        onLabelRemoved={handleLabelRemoved}
      />
    ));
  };

  return (
    <div>
      <h3 className="mb5">
        Labels
        <button type="button" className="btn btn-secondary btn-sm mr-1 pull-right" onClick={onCollapseClick}>
          { isOpen ? (
            <FontAwesomeIcon icon={['far', 'fa-minus']} />
          ) : (
            <FontAwesomeIcon icon={['far', 'fa-plus']} />
          )}
        </button>
      </h3>

      <Collapse in={isOpen}>
        <div>
          <div className="mr25 ml30 float-right timeline-label">
            {canManageLead && (
              <LabelingForm
                leadID={lead.id}
                onLabelCreated={handleLabelCreated}
              />
            )}
          </div>

          <div className="labels mt-2">{renderLeadLabels()}</div>
        </div>
      </Collapse>
    </div>
  );
};

LeadDrawerLabels.defaultProps = {
  lead: null,
};

LeadDrawerLabels.propTypes = {
  lead: PropTypes.shape({}),
};

export default LeadDrawerLabels;

/**
 * Validates if value(s) exist in a list of options
 * @param {string|number|Array<string|number>} value - The value(s) to validate
 * @param {Array<{value: string|number}>} options - Array of option objects containing value property
 * @param {Function} onChange - Callback function to call when validation fails
 * @param {string|number} [skipValue='-1'] - Value to skip validation for (e.g. special cases like "Contact's Owner")
 * @returns {void}
 */
export const validateOptionValueExists = (value, options, onChange, skipValue = '-1') => {
  // Handle multi-select case (array of values)
  if (Array.isArray(value)) {
    const validValues = value.filter(val => {
      if (val === skipValue) return true;
      return options.some(option => option.value === val);
    });

    // If any values were invalid, update with only valid values
    if (validValues.length !== value.length) {
      onChange(validValues.length ? validValues : null);
    }
    return;
  }

  // Handle single-select case
  if (value === skipValue) return;

  const isValidValue = options.some(option => option.value === value);
  if (!isValidValue) {
    onChange(null);
  }
};

import classNames from 'classnames';
import React from 'react';
import numeral from 'numeral';
import parsePhoneNumber from 'libphonenumber-js';

const TaskStatusMappings = {
  to_do:       'To Do',
  in_progress: 'In Progress',
  done:        'Done',
  canceled:    'Canceled',
};

const TaskCategoryMappings = {
  follow_up:    'Follow Up',
  call:         'Call',
  email:        'Email',
  sms_text:     'SMS Text',
  appointment:  'Appointment',
  letter:       'Letter',
  social_media: 'Social Media',
  other:        'Other',
};

const TaskPriorityMappings = {
  high:   'High',
  normal: 'Normal',
  low:    'Low',
};

/**
 * Lead specific helper methods
 * @type {Object} LeadHelpers - set of helpers methods
 */
const LeadHelpers = {
  /**
   * Convert the date to 'how many days past' format. Special treatment for 'today' and 'yesterday'.
   * @param {string} date string
   * @returns {string}
   */
  daysFromNowString(m) {
    const submittedDate = Moment(m).startOf('day');
    const today = Moment().startOf('day');

    if (today.diff(submittedDate, 'days') < 2) {
      return submittedDate.calendar().split(' ')[0]; // 'Today', 'Tomorrow'
    }

    return submittedDate.from(today); // '2 days ago' etc.
  },

  /**
   * Return lead first_name last_name as a full name
   * @param {Object} lead
   * @returns {string}
   */

  renderLeadName(lead) {
    return _lodash.compact([lead.first_name, lead.last_name]).join(' ');
  },

  leadFullNameWithCompanyQuery(lead) {
    return `${lead.first_name} ${lead.last_name} ${lead.currently_at || ''}`;
  },

  renderTaskStatus(status) {
    if (!status) {
      return null;
    }

    const label = TaskStatusMappings[status];

    return label;
  },

  renderTaskPriority(priority) {
    if (!priority) {
      return null;
    }

    const label = TaskPriorityMappings[priority];

    return label;
  },

  renderTaskCategory(category) {
    if (!category) {
      return null;
    }

    const label = TaskCategoryMappings[category];

    return label;
  },

  /**
   * Render lead avatar from photo_url
   * @param {Object} lead
   * @returns {Element}
   */
  renderLeadAvatar(lead, opts = {}) {
    if (!lead) {
      return null;
    }

    const props = {
      alt:       LeadHelpers.renderLeadName(lead),
      className: classNames('rounded-circle', opts.className),
      width:     '27',
      height:    '27',
    };

    let leadPhoto;
    if (lead.photo_url) {
      leadPhoto = <img alt={lead.name} src={lead.photo_url} {...props} />;
    }

    return leadPhoto;
  },

  formatPhoneNumber(phone) {
    if (!phone) {
      return null;
    }

    const s2 = `${phone}`.replace(/\D/g, '');
    const m = s2.match(/^[+1]?(\d{3})(\d{3})(\d{4})$/);
    const str = !m ? phone : `(${m[1]}) ${m[2]}-${m[3]}`;

    return str;
  },

  nationalFormatPhoneNumber(phone_number) {
    if (phone_number) {
      const phoneNumber = parsePhoneNumber(phone_number, 'US');

      if (phoneNumber) return phoneNumber.formatNational();
    }

    return null;
  },

  renderAppointmentAt(time) {
    const timezone = Moment.tz.guess();

    return (
      <>
        {Moment(time).tz(timezone).format('dddd, MMMM Do YYYY, h:mm a')}
        {' '}
        {this.tzAbbr(timezone)}
      </>
    );
  },

  renderPhoneNumber(phone) {
    const str = LeadHelpers.formatPhoneNumber(phone);
    return <span dangerouslySetInnerHTML={{ __html: str }} />;
  },

  agentAlertFullAddress(alert) {
    const { unparsed_address, postal_city, state, postal_code } = alert || {};
    return _lodash.compact([unparsed_address, postal_city, state, postal_code]).join(', ');
  },

  shouldShowGoogleMapLink(alert) {
    const validAlertTypes = [
      'New listing',
      'New closing',
      'New contract',
      'New pending',
      'Office change'
    ];

    return (
      validAlertTypes.includes(alert?.alert_type) &&
      alert?.unparsed_address &&
      alert?.unparsed_address !== 'NA'
    );
  },

  /**
   * Render user avatar from avatar_url
   * @param {Object} user
   * @returns {Element}
   */
  renderUserAvatar(user) {
    if (!user) {
      return null;
    }

    let userAvatar;
    if (user.avatar_url) {
      userAvatar = (
        <img
          src={user.avatar_url}
          alt="User avatar"
          className="rounded-circle"
          width="27"
          height="27"
        />
      );
    }

    return userAvatar;
  },

  /**
   * Render lead production badge
   * @param {Object} lead
   * @returns {XML}
   */
  renderProductionBadge(lead, opts = {}) {
    const productionLabels = Rails.helpers.currentConfiguration.production_labels;

    if (!lead || !lead.production_label) {
      return null;
    }

    let badgeClass = 'success';

    switch (productionLabels[lead.production_label]) {
      case productionLabels.mega:
        badgeClass = 'danger';
        break;
      case productionLabels.super:
        badgeClass = 'warning';
        break;
      default:
        badgeClass = 'success';
    }

    return (
      <span
        {...opts}
        className={classNames(
          opts.className,
          `badge badge-outline-${badgeClass}`,
        )}
      >
        {productionLabels[lead.production_label].toUpperCase()}
      </span>
    );
  },

  /**
   * Render lead production value
   * @param {Object} field
   * @returns {string|null}
   */
  renderProductionValue(field, opts = {}) {
    if (!field) {
      return null;
    }

    return (
      <span {...opts} className={opts.className}>
        {numeral(field).format('$0,0[.]00')}
      </span>
    );
  },

  renderDuration(durationSec) {
    const duration = Moment.duration(durationSec, 'seconds').format('hh:mm:ss', { trim: false });

    return `- duration ${duration}`;
  },

  /**
   * Render lead date field
   * @param {Object} lead
   * @param {string} timestampField
   * @param {string} timeFormat - default to 'LL'
   * @returns {string}
   */
  renderLeadDateField(lead, timestampField, timeFormat = 'LL') {
    if (!lead || !lead[timestampField]) {
      return null;
    }
    if (timeFormat === 'fromNow') {
      return Moment(lead[timestampField]).fromNow();
    }
    return Moment(lead[timestampField]).format(timeFormat);
  },

  /**
   *  Render rating value as text name
   * @param {number} leadRating - Lead rating to be display
   * @returns {string} Rating value in text
   */
  renderRatingText(leadRating) {
    const rating = parseInt(leadRating, 10);

    switch (rating) {
      case 11:
        return 'Joined this month';
      case 10:
        return 'Joining this month';
      case 9:
        return 'Joining next month';
      case 8:
        return 'Joining in 2 months';
      case 7:
        return 'Joining in 3 months';
      case 6:
        return 'Good meeting';
      case 5:
        return 'Meeting could have gone better';
      default:
        return 'No meeting';
    }
  },

  parseAddressComponents(addressComponents) {
    const readableAddress = {};

    const componentForm = {
      street_number:               'short_name',
      route:                       'long_name',
      locality:                    'long_name',
      administrative_area_level_1: 'short_name',
      country:                     'long_name',
      postal_code:                 'short_name',
    };

    for (let i = 0; i < addressComponents.length; i += 1) {
      const addressType = addressComponents[i].types[0];
      if (componentForm[addressType]) {
        const val = addressComponents[i][componentForm[addressType]];
        readableAddress[addressType] = val;
      }
    }

    return readableAddress;
  },

  /**
   *  Render timezone abbreviation
   * @param {string} timezone - Timezone to display the abbreviation for
   * @returns {string}
   */
  tzAbbr(timezone) {
    if (!timezone) return null;

    const timezoneMapping = { 'America/Phoenix': 'Arizona' };

    return timezoneMapping[timezone] || Moment().tz(timezone).format('z');
  },

  /**
   * Determines the status stage based on the product.
   *
   * @param {string} product - The current product.
   * @returns {string} The determined status stage.
   */
  determineStatusStageByProduct(product) {
    const productMap = {
      archived:   '',
      retention:  'retention',
      recruiting: 'recruiting_with_shared',
    };

    return productMap[product] || '';
  },

  /**
  * Determines the status stage based on lead attributes.
  *
  * @param {Object} lead - The lead object with attributes.
  * @returns {string} The determined status stage.
  */
  determineStatusStage(lead) {
    if (lead.archived) {
      return '';
    } if (lead.recruiting) {
      return 'recruiting_with_shared';
    } if (lead.retention) {
      return 'retention';
    }

    return '';
  },

  findStatusLabel(value, statuses) {
    const status = statuses.find((s) => s.value === value);
    return status ? status.label : '';
  },

  updateStatusOnCommunicationActivity(status, activityType, lead) {
    const isStatusAfterContactAttempt = Array.isArray(lead.statuses_after_contact_attempt) && !lead.statuses_after_contact_attempt.includes(status);
    const isStatusAfterApptSet = Array.isArray(lead.statuses_after_appt_set) && !lead.statuses_after_appt_set.includes(status);
    const isStatusAfterNeedsEvaluated = Array.isArray(lead.statuses_after_need_evaluated) && !lead.statuses_after_need_evaluated.includes(status);

    switch (activityType) {
      case 'phone_call':
      case 'text_message':
      case 'email':
      case 'direct_email':
      case 'direct_text_message':
        if (isStatusAfterContactAttempt) return 'contact_attempt';
        break;
      case 'appointment':
        if (isStatusAfterApptSet) return 'appt_set';
        break;
      case 'log_appointment':
        if (isStatusAfterNeedsEvaluated) return 'needs_evaluated';
        break;
      default:
        break;
    }

    return status;
  },
};

export default LeadHelpers;

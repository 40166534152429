import React, { useState, useCallback } from 'react';
import PropTypes           from 'prop-types';
import { Link }            from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIRequest          from '~/lib/api_request';
import brokerbit           from '~/lib/brokerbit';

const LeadDrawerLabel = ({ leadID, label, onLabelRemoved }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleLabelDelete = useCallback(async (e) => {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  `Are you sure to remove the "${label.name}" label?`,
      callback: async (ok) => {
        if (!ok) return;

        setIsDeleting(true);

        try {
          const request = APIRequest.delete({
            resource: `/v1/leads/${leadID}/labels/${label.id}`,
          });

          await new Promise((resolve, reject) => {
            request.end((error, response) => {
              if (error) reject(error);
              else resolve(response);
            });
          });

          onLabelRemoved?.(label);
        } catch (error) {
          console.error('Error removing label:', error);
          setIsDeleting(false);
        }
      },
    });
  }, [leadID, label, onLabelRemoved]);

  return (
    <div className="mr-2 mb-2 d-inline-block label">
      <span className="badge badge-success align-middle">
        <Link
          to="#"
          onClick={isDeleting ? undefined : handleLabelDelete}
          className="mr-1 align-middle"
          style={{ cursor: isDeleting ? 'default' : 'pointer' }}
        >
          {isDeleting ? (
            <FontAwesomeIcon
              icon="far fa-spinner"
              pulse
              size="sm"
              className="mr-1 text-white"
            />
          ) : (
            <FontAwesomeIcon
              icon={['far', 'fa-times']}
              size="sm"
              className="mr-1 text-white"
            />
          )}
        </Link>
        {label.name}
      </span>
    </div>
  );
};

LeadDrawerLabel.propTypes = {
  leadID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label:  PropTypes.shape({
    id:   PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onLabelRemoved: PropTypes.func,
};

LeadDrawerLabel.defaultProps = {
  onLabelRemoved: () => {},
};

export default LeadDrawerLabel;
